import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatosGeneralesDemandaComponent } from './datos-generales-demanda/datos-generales-demanda.component';
import { PrestacionesComponent } from './prestaciones/prestaciones.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { HttpClientModule } from '@angular/common/http';
import { FiscalizacionComponent } from './fiscalizacion/fiscalizacion.component';
import { MenuFiscComponent } from './menu-fisc/menu-fisc.component';
import { CorregirComponent } from './corregir/corregir.component';
import { RevisarComponent } from './revisar/revisar.component';
import { AdministradorComponent } from './admin/administrador/administrador.component';
import { GestionColaboradoresComponent } from './admin/gestion-colaboradores/gestion-colaboradores.component';
import { ReportesComponent } from './admin/reportes/reportes.component';
import { GestionExpedientesComponent } from './admin/gestion-expedientes/gestion-expedientes.component';
import { AsignarFiscalizadorComponent } from './admin/asignar-fiscalizador/asignar-fiscalizador.component';


@NgModule({
  declarations: [
    AppComponent,
    DatosGeneralesDemandaComponent,
    
    PrestacionesComponent,
         NavbarComponent,
         LoginComponent,
         MenuComponent,
         FiscalizacionComponent,
         MenuFiscComponent,
         CorregirComponent,
         RevisarComponent,
         AdministradorComponent,
         GestionColaboradoresComponent,
         ReportesComponent,
         GestionExpedientesComponent,
         AsignarFiscalizadorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
