import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import {URLs} from './variablesDebug.service';
var baseUrl;

@Injectable({
  providedIn: 'root'
})

export class LigasService{
    constructor(private http: HttpClient, private urls: URLs,) {
        urls=new URLs();
        if (urls.debug==true){
            baseUrl=urls.debugUrl+'api/';
        }
        else{
            baseUrl=urls.devUrl+'api/';
        }
      }
    
    getAll(idExpediente?:string) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        let params = new HttpParams();
        if(idExpediente!=undefined && idExpediente !=''){
        params = params.append('idExpediente',idExpediente+"");
        }
        return new Promise(resolve=>{
            this.http.get(baseUrl+'ligas', {'headers':headers, 'params':params}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    resolve(error);
                }
            )
        });
    }

    createLiga(data) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'ligas', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    resolve(error);
                }
            )
        });
    }

    updateLiga(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.put(baseUrl+'ligas', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    resolve(error);
                }
            )
        });
        
    }

    deleteLiga(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        let params = new HttpParams().append("idExpediente",data);
        return new Promise(resolve=>{
            this.http.delete(baseUrl+'ligas', {'headers':headers,'params':params}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    resolve(error);
                }
            )
        });
        
    }

    subirLigas(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'grupoligas', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    resolve(error);
                }
            )
        });
    }
    
}