<div class="row p-auto m-auto w-100 h-100">
    <div class="col-12 w-100 h-100" >
        <div class="card p-auto mx-auto w-50 h-50 position-absolute top-50 start-50 translate-middle" >
            <div class="card-body ">
              <form [formGroup]="form">
                <h1 class="h4 mb-3 font-weight-normal">Ingresa al sistema</h1>
                
                <label for="inputEmail" class="sr-only">Nombre de usuario</label>
                <input type="email" id="inputUser" class="form-control" placeholder="Usuario" formControlName="emailInput" autofocus="" >
                <div class="error-msg" *ngIf="(this.form.get('emailInput').invalid && (this.form.get('emailInput').invalid && this.form.get('emailInput').touched))">
                  Email requerido
                </div>
                <br>
                <label for="inputPassword" class="sr-only">Contraseña</label>
                <input type="password" id="inputPassword" class="form-control" placeholder="Contraseña" formControlName="psswd">
                <div class="error-msg" *ngIf="((this.form.get('psswd').invalid && this.form.get('psswd').touched)||(this.form.get('psswd').invalid && buttonBool))">
                  Contraseña requerida
                </div>
                <button class="btn btn-lg btn-block position-absolute bottom-0 start-50 translate-middle-x my-3" style=" background-color: #339caa" [disabled]="this.form.invalid" type="submit" (click)="submit()">Ingresar</button>
            </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Atención, usuario o contraseña incorrectos.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>

