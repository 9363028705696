<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 font-weight-normal">Agregar colaborador</h1>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label for="nomColab">Nombre y apellidos</label>
                            <input type="text" id="nomColab" class="form-control" placeholder="Nombre colaborador"
                                formControlName="nombreC">
                        </div>
                        <div class="col" >
                            <label for="tipoColab">Tipo</label>
                            <select class="form-control" id="tipoColab" formControlName="tipoCo" (change)="tipoColab()">
                                
                                <option value="A">Capturista</option>
                                <option value="B">Fiscalizador</option>
                                <option value="C">Administrador</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col" id="fiscDiv" >
                            <label for="tipoColab">Asignar Fiscalizador</label>
                            <select class="form-control" id="fiscalizadoresS"  >
                                <option *ngFor="let repre of fiscalizadores;  let id = index" value="{{repre.correo}}">
                                    {{repre.nombre}}</option>
                            </select>
                        </div>

                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label for="correo">Correo electrónico</label>
                            <input type="email" id="correo" class="form-control" placeholder="ejemplo@ejemplo.com" formControlName="correo">
                        </div>
                        <div class="col">
                            <label for="correoConf">Correo electrónico
                                (confirmación)</label>
                            <input type="email" id="correoConf" class="form-control" placeholder="ejemplo@ejemplo.com" formControlName="correo2"(change)="comprobar2()" >
                        </div>
                        <div class="error-msg" *ngIf="!comprobarCorreoContr">
                            ERROR: El correo no coincide con la confirmación
                            
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label for="psswd">Contraseña</label>
                            <input type="password" id="psswd" class="form-control" formControlName="contraseña">
                        </div>
                        <div class="col">
                            <label for="psswdConf">Contraseña (confirmación)</label>
                            <input type="password" id="psswdConf" class="form-control" (change)="comprobar()" formControlName="contraseña2">
                        </div>
                        <div class="error-msg" *ngIf="!comprobarContr">
                            ERROR: contraseña no coincide con la confirmación
                            
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col mx-auto">
                            <button class="btn btn-greenL" type="button" (click)="nuevoUsuario()"
                                >Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <h1 class="h4 mb-3 font-weight-normal">Eliminar
                                colaborador</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="eliminarColabNombre">Elegir colaborador</label>
                            <select class="form-control" id="eliminarColabNombre" >
                                
                                <option *ngFor="let repre of capturistas;  let id = index" value="{{repre.correo}}">
                                        {{repre.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <button class="btn btn-greenL" type="button"
                                (click)="deleteColaborador()">Eliminar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 mb-3 font-weight-normal">Ver colaborador
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="colaboradoresActuales">Elegir colaborador</label>
                            <select class="form-control" id="colaboradoresActuales" >
                                <option *ngFor="let repre of fiscalizadores;  let id = index" value="{{repre.correo}}">
                                    {{repre.nombre}}</option>
                                <option *ngFor="let repre of capturistas;  let id = index" value="{{repre.correo}}">
                                        {{repre.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <button class="btn btn-greenL" type="button" (click)="infoColaborador()">Ver información</button>
                        </div>
                        
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <div class="card m-0 p-0" *ngIf="infoColabFlag">
                                <div class="card-body" id="infoColab">
                                    <div *ngIf="!capturistaInfo">
                                        <ul class="list-group">
                                            <li class="list-group-item active">Fiscalizador</li>
                                            <li class="list-group-item">Nombre: {{nombreColab}}</li>
                                            <li class="list-group-item">Correo: {{correo}}</li>
                                            <li class="list-group-item">Total de capturistas asignados: {{totalExpedientesAsignados}}</li>
                                            <li class="list-group-item">Total de expedientes fiscalizados: {{totalExpedientesFiscalizados}}</li>
                                            <li class="list-group-item">Total de expedientes por fiscalizar: {{totalExpedientesPorFiscalizar}}</li>
                                          </ul>
                                    </div>
                                    <div *ngIf="capturistaInfo">
                                        <ul class="list-group">
                                            <li class="list-group-item active">Capturista</li>
                                            <li class="list-group-item">Nombre: {{nombreColab}}</li>
                                            <li class="list-group-item">Correo: {{correo}}</li>
                                            <li class="list-group-item">Total de expedientes capturados: {{totalExpedientesCapturados}}</li>
                                            <li class="list-group-item">Total de expedientes finalizados: {{totalExpedientesFinalizados}}</li>
                                            <li class="list-group-item">Total de expedientes fiscalizados: {{totalExpedientesFiscalizados}}</li>
                                            <li class="list-group-item">Total de expedientes a fiscalizar: {{totalExpedientesaFiscalizar}}</li>
                                            <li class="list-group-item">Total de expedientes a corregir: {{totalExpedientesaCorregir}}</li>
                                          </ul>
                    

                                    </div>
                                    
                                      
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
</div>
<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 mb-3 font-weight-normal">Cambiar límite de expedientes del colaborador
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="colaboradoresActuales">Elegir colaborador</label>
                            <select class="form-control" id="colaboradoresLim" >
                                
                                <option *ngFor="let repre of capturistas;  let id = index" value="{{repre.correo}}">
                                        {{repre.nombre}}</option>
                            </select>
                        </div>
                        <div class="col">
                            <label for="numExp">Nuevo límite</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="limite">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <button class="btn btn-greenL" type="button" (click)="modificarLim()">Modificar límite</button>
                        </div>
                        
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
    
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{mensaje}}
        </div>
        <div class="modal-footer">
            <div class="row" >
                <div class="col">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="confirmaDelete()">Guardar</button>

                </div>
                <div class="col">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    
                </div>
          
            </div>
        </div> 
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{mensaje}}
        </div>
        <div class="modal-footer">
            <div class="row" >
                
                <div class="col">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    
                </div>
          
            </div>
        </div> 
      </div>
    </div>
  </div>

