import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asignar-fiscalizador',
  templateUrl: './asignar-fiscalizador.component.html',
  styleUrls: ['./asignar-fiscalizador.component.css']
})
export class AsignarFiscalizadorComponent implements OnInit {

  banderaLlenarFiscalizadores = true;
  banderaColabsXFis = true;
  banderaLlenarCapturistasSinFiscalizador = true;

  constructor() { }

  ngOnInit(): void {
  }

  asignacionCaptFis(){}
  
  cargarCaptXFisc(){}

  desasociarFisc(){}

}
