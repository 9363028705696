import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'; //Bibliotecas usadas para validacion
import {CapturistasService} from '../../services/capturistas.service';
import { ControlesService } from 'src/app/services/controles.service';
import { FiscalizadoresService } from 'src/app/services/fiscalizadores.service';
import { LigasService } from 'src/app/services/ligas.service';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-gestion-colaboradores',
  templateUrl: './gestion-colaboradores.component.html',
  styleUrls: ['./gestion-colaboradores.component.css']
})
export class GestionColaboradoresComponent implements OnInit {
  fiscalizadores: any;
  comprobarContr: boolean;
  capturistas: JSON[];
  colaboradores: JSON[];
  infoColab: unknown;
  myModal ;
  myModal2;
  mensaje="";
  colab2: any;
  fiscHidden: boolean=false;
  capturistaInfo: boolean=false;
  nombreColab: any;
  correo: any;
  totalExpedientesCapturados: any;
  totalExpedientesFiscalizados: any;
  totalExpedientesFinalizados: any;
  totalExpedientesaCorregir: any;
  totalExpedientesaFiscalizar: any;
  totalExpedientesAsignados: any;
  totalExpedientesPorFiscalizar: any;


  constructor(private formBuilder: FormBuilder, private apiCapturista: CapturistasService, private apiControl:ControlesService, 
    private apiFiscalizador:FiscalizadoresService, private apiLigas: LigasService) { }

  form: FormGroup;

  comprobarCorreoContr = true;
  botonAddColab = true;
  banderaLlenarColabs = false;
  dispSpin = true;
  infoColabFlag = false;

  listaCapturistas:Array<String>=[]
  listaFiscalizadores:Array<String>=[]

  ngOnInit(): void {
    this.recuperaInfo();
    this.traerFiscalizadores();
    this.traerCapturistas();
    this.myModal= new Modal(document.getElementById('exampleModal'));
    this.myModal2= new Modal(document.getElementById('exampleModal2'));
    (document.getElementById("fiscDiv") as HTMLInputElement).style.display = "none";

    this.form = this.formBuilder.group({
      
    
      // datos gen del trabajador
      nombreC: ['',Validators.required],
      tipoCo: ['',Validators.required],
      
      correo: ['',Validators.required],
      correo2: ['',Validators.required],
      contraseña: ['',Validators.required],
      contraseña2: ['',Validators.required],
    });
  }

  comprobar(){
    var password=(document.getElementById("psswd") as HTMLInputElement).value;
    var password2=(document.getElementById("psswdConf") as HTMLInputElement).value;
    if(password != password2){
      this.comprobarContr= false;
    }else{
      this.comprobarContr=true;
    }
  }
  comprobar2(){
    var password=(document.getElementById("correo") as HTMLInputElement).value;
    var password2=(document.getElementById("correoConf") as HTMLInputElement).value;
    if(password != password2){
      this.comprobarCorreoContr= false;
    }else{
      this.comprobarCorreoContr=true;
    }
  }
  validaciones(){
    
    if(this.form.invalid || !this.comprobarContr || !this.comprobarCorreoContr){
      this.botonAddColab=true;
    }
    else{
      this.botonAddColab=false;
    }
    //console.log(this.form.invalid, !this.comprobarContr,!this.comprobarCorreoContr)

  }


  deleteColaborador(){
    var colab= (document.getElementById("eliminarColabNombre") as HTMLInputElement).value;
    
    this.mensaje="Por favor confirme que desea eliminar";
    this.myModal.toggle();
    
  }
  confirmaDelete(){
    var colab= (document.getElementById("eliminarColabNombre") as HTMLInputElement).value;
    this.eliminarExpedientesCapturista(colab);
    this.recuperaInfo();
    this.traerFiscalizadores();
    this.traerCapturistas();

  }

  infoColaborador(){
    var colab= (document.getElementById("colaboradoresActuales") as HTMLInputElement).value;
    this.informacionColaborador(colab);
    this.infoColabFlag=true;
    
  }

  recuperaInfo(){
    this.apiCapturista.getAll().then((res:Array<JSON>)=>{
      var result=res;
      for(var i=0; i<result.length;i++){
        this.listaCapturistas.push(result[i]['correo']);
      }
      return this.apiFiscalizador.getAll();
    }).then((res:Array<JSON>)=>{
      var result=res;
      for(var i=0; i<result.length;i++){
        this.listaFiscalizadores.push(result[i]['correo']);
      }
    });
  }
  nuevoUsuario(){
    this.validaciones();
    if(!this.botonAddColab){
      var email=(document.getElementById("correo") as HTMLInputElement).value;
      var password=(document.getElementById("psswdConf") as HTMLInputElement).value;
      var tipo=(document.getElementById("tipoColab") as HTMLInputElement).value;
      var nombre=(document.getElementById("nomColab") as HTMLInputElement).value;
      var tipoAcceso=(document.getElementById("tipoColab") as HTMLInputElement).value;
      var fiscalizador=(document.getElementById("fiscalizadoresS") as HTMLInputElement).value;
      let tipo2:number;
      if(tipo=="A"){
        tipo2=4;//En producción es 4 en debug, depende de como instanciado
      }
      if(tipo=="B"){  
        tipo2=2;
      }
      if(tipo=="C"){
        tipo2=3;
      }
      
      this.crearUsuario(password, email, tipo2, nombre, tipoAcceso, fiscalizador);
      this.recuperaInfo();
      
      

    }else{
      this.mensaje="Datos incompletos, porfavor revise la información.";
      this.myModal2.toggle();

    }

    
  }
  traerFiscalizadores(){
    
    this.apiFiscalizador.getAll().then((result:Array<JSON>)=>{
      console.log(result);
      this.fiscalizadores=result;
    })
    
  }
  traerCapturistas(){
    
    this.apiCapturista.getAll().then((result:Array<JSON>)=>{
      console.log(result);
      this.capturistas=result;
    })
    
  }
  tipoColab(){
    if((document.getElementById("tipoColab") as HTMLInputElement).value=="A"){
      (document.getElementById("fiscDiv") as HTMLInputElement).style.display = "block";
    }else{
      (document.getElementById("fiscDiv") as HTMLInputElement).style.display = "none";
    }
  }
  
  crearUsuario(password, email, tipo, nombre, tipoAcceso, fiscalizador){
    //tipo: 1-Capturista, 2-Fiscalizador, 3-Admin
    var datos ={
      "username":email,
      "password":password,
      "email":email,
      "groups":[tipo],
      "nombre":nombre,
      "tipoAcceso":tipoAcceso,
      "fiscalizador":fiscalizador
    }
    console.log(datos);
    this.apiCapturista.createUser(datos).then(result=>{
      console.log(result);
      if (result["status"]>=400){
        console.log("Error al crear el usuario");
        this.mensaje="Error al crear el usuario";
        this.myModal2.toggle();
      }else{ 
        this.traerCapturistas();
        this.traerFiscalizadores();
        this.mensaje="Colaborador agregado correctamente.";
        this.myModal2.toggle();
      }
    });
  }

  cambiarFiscalizador(emailCapturista, emailFiscalizador){
    var datos = {
      "correo": emailCapturista,
      "fiscalizador":emailFiscalizador
    }
    this.apiCapturista.updateCap(datos).then(result=>{
      console.log(result);
    })
  }
  
  informacionColaborador(correo){
    this.apiCapturista.infoColaborador(correo).then(result=>{
      console.log(result);
      var tipo=result["tipoAcceso"];
      
      if(tipo=="B"){
        tipo="Fiscalizador";
        this.capturistaInfo= false;
        this.nombreColab=result["nombre"];
        this.correo=result["correo"];
        this.totalExpedientesAsignados= result["totalCapturistasAsignados"];
        this.totalExpedientesFiscalizados=result["totalExpedientesFiscalizados"];
        this.totalExpedientesPorFiscalizar=result["totalExpedientesPorFiscalizar"];
      
      }

      if(tipo=="A"){
        tipo="Capturista";
        this.capturistaInfo= true;
        this.nombreColab=result["nombre"];
        this.correo=result["correo"];
        this.totalExpedientesCapturados= result["totalExpedientesCapturados"];
        this.totalExpedientesFiscalizados=result["totalExpedientesFinalizados"];
        this.totalExpedientesFinalizados=result["totalExpedientesFinalizados"];
        this.totalExpedientesaCorregir=result["totalExpedientesaCorregir"];
        this.totalExpedientesaFiscalizar= result["totalExpedientesaFiscalizar"];
      
      }
      if (result["status"]>=400){
        console.log("No se encontró al usuario con ese correo");
      }
    });
  }

  desasignarExpediente(idExpediente){
    this.apiControl.eliminarControl(idExpediente).then(result=>{
      console.log(result);
      if (result["status"]>=400){
        console.log("Error al borrar el control");
        return null;
      }
      else{
        var dataLigas={
          "idExpediente":idExpediente,
          "prioridad":1
        }
        return this.apiLigas.updateLiga(dataLigas);
      }
    }).then(result=>{
      console.log(result);
      if (result["status"]>=400){
        console.log("Error al actualizar liga");
      }
    });
  }

  finalizarExpediente(idExpediente){
    var dataExp = {
      "idExpediente":idExpediente,
      "estatus":"fin"
    }
    return this.apiControl.updateCon(dataExp).then(result=>{
      console.log(result);
      if (result["status"]>=400){
        console.log("Error al actualizar control");
      }
    });
  }

  eliminarExpedientesCapturista(correo){
    this.apiControl.getAll('',correo).then((res:Array<JSON>)=>{
      var result=res;
      for (var i=0; i<result.length; i++){
        if (result[i]['estatus'] == 'aC' || result[i]['estatus'] == 'aF' || result[i]['estatus'] == 'c' || result[i]['estatus'] == 'eP'){
          this.desasignarExpediente(result[i]['idExpediente']);
        }
        if (result[i]['estatus'] == 'fis'){
          this.finalizarExpediente(result[i]['idExpediente']);
        }
      }
    });
  }
  modificarLim(){
    var email=(document.getElementById("colaboradoresLim") as HTMLInputElement).value;
    var lim=(document.getElementById("limite") as HTMLInputElement).value;
    this.modificarLimiteExpedientes(email,lim);
    this.mensaje="Límite actualizado correctamente.";
    this.myModal2.toggle();
  }

  modificarLimiteExpedientes(correo,limite){
    var datos = {
      "correo": correo,
      "limiteExp":limite
    }
    this.apiCapturista.updateCap(datos).then(result=>{
      console.log(result);
    });
  }

}
