<div class="row header fixed-top">    
  <div class="row">
    <div class="col">
     
    </div>
    <div class="col-6">
      <p class="h1">Captura Iniciales</p>
    </div>
    <div class="col">
      <button type="button" class="btn btn-green btn-sm btn-block" (click)="goMenu()">Menu</button>
      <button type="button" class="btn btn-green btn-sm btn-block" (click)="logout()">Cerrar sesión</button>
             
    </div>
  </div>
    
    <div class="col-8 d-flex ">
      
      
     
      
    </div>
  </div>

