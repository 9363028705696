import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'; //Bibliotecas usadas para validacion
import { Router } from "@angular/router";

import { LoginService } from '../services/login.service';
import { CapturistasService } from '../services/capturistas.service';
import { ControlesService } from '../services/controles.service';
import { DemandasService } from '../services/demandas.service';
import { TrabajadoresService } from '../services/trabajadores.service';
import { LigasService } from '../services/ligas.service';
import { Modal } from 'bootstrap';

import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-datos-generales-demanda',
  templateUrl: './datos-generales-demanda.component.html',
  styleUrls: ['./datos-generales-demanda.component.css']
})
export class DatosGeneralesDemandaComponent implements OnInit {
  url = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  //url="https://drive.google.com/file/d/1XeqCGwzzo_nALP9Qwi-1fQMiLBBfhR-Q/preview"
  //url = "https://drive.google.com/viewerng/viewer?url=http://docs.google.com/fileview?id=1qZcyZsISdrbQ2RhN2sRCY_zPE_74EGIx&hl=en&pid=explorer&efh=false&a=v&chrome=false&embedded=true"
  //url = "https://drive.google.com/viewerng/viewer?embedded=true&url=https://drive.google.com/file/d/1qZcyZsISdrbQ2RhN2sRCY_zPE_74EGIx&hl=en&pid=explorer&efh=false&a=v&chrome=false&embedded=true";
  form: FormGroup;
  form2: FormGroup;
  

  pdfSource;
  today = new Date().toJSON().split('T')[0];
  
  boolNumHorasExtras: boolean = true;
  boolfechaIniAguinaldo: boolean = true;
  boolfechaFinAguinaldo: boolean = true;
  boolRazonAguinaldo: boolean = true;
  boolPorcentajePrimaVac: boolean = true;
  boolMontoPrimaVac: boolean = true;
  boolFechaInicialVac: boolean = true;
  boolfechaFinalVac: boolean = true;
  boolRazonVac: boolean = true;
  boolMontoPrimaAntiguedad: boolean = true;
  boolMontoSalariosCaidos: boolean = true;
  boolIndemn20: boolean = true;
  boolIndemn: boolean = true;
  boolCausa: boolean = true;
  boolDespacho: boolean = true;
  expediente: string;
  anio: string;
  junta: string;
  demanda: any;
  trabajadores: any;
  agregarAct: boolean=false;
  actualizarAct: boolean=true;
  trabajadorAct: any;
  buttonBoolDem: boolean=false;
  boolValidacionesDemanda: boolean=true;
  demandaExistente: boolean;
  boolValidacionesTrabajador: boolean=true;
  boolValidacionesRequiredDemanda: boolean=true;
  boolValidacionesRequiredTrabajador: boolean=true;
  myModal ;
  mensaje="";
  usuario= JSON.parse(localStorage.getItem("userIni"))["usuario"];
  expedienteAsignado: any;
  myModal2: Modal;





  constructor(private route: ActivatedRoute, private dom: DomSanitizer, private formBuilder: FormBuilder, private router: Router,
    private apiLogin: LoginService, private apiDemanda: DemandasService, private apiControl: ControlesService, private apiTrabajador: TrabajadoresService,
    private apiCapturista: CapturistasService, private apiLigas: LigasService) { 
    this.pdfSource = this.dom.bypassSecurityTrustResourceUrl(this.url);
   
  }

  f = new Date();
  actual = this.f.getFullYear();
  aniosExp = [this.actual, this.actual - 1, this.actual - 2];

  actores: Array<any> = []; //Incluye nombres y direcciones
  actorContactos: Array<any> = [];
  //Variables auxiliares para el manejo de actores
  actoresEliminados = [];
  actoresAgregados = [];
  actor: any;
  minDateVac: String | undefined;
  minDateAg: String | undefined;
  minDateH: String | undefined;
  boolfechaIniHorasExtras: boolean = true;
  buttonBool = false;
  boolfechaFinHorasExtras: boolean = true;




  ngOnInit(): void {
    
    this.myModal2= new Modal(document.getElementById('exampleModal2'));
    this.myModal= new Modal(document.getElementById('exampleModal'));

    //Defaults para los acordeones por secciones
    (document.getElementById("seccionDemanda") as HTMLBodyElement).style.display = "none";
    (document.getElementById("seccionActores") as HTMLBodyElement).style.display = "none";
    (document.getElementById("seccionObservaciones") as HTMLBodyElement).style.display = "none";


    (document.getElementById("causaEspecifica") as HTMLInputElement).disabled = true;
    
    //defaults para montos
    (document.getElementById("montoIndemn") as HTMLInputElement).style.display = "none";
    (document.getElementById("montoIndemn20") as HTMLInputElement).style.display = "none";
    (document.getElementById("montoSalariosCaidos") as HTMLInputElement).style.display = "none";
    (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).style.display = "none";
    (document.getElementById("vacaciones") as HTMLInputElement).style.display = "none";
    (document.getElementById("vacaciones2") as HTMLInputElement).style.display = "none";
    (document.getElementById("aguinaldo") as HTMLInputElement).style.display = "none";
    (document.getElementById("horasExtras") as HTMLInputElement).style.display = "none";
    this.form = this.formBuilder.group({
      
    
      // datos gen del trabajador
      nomActor1: ['', Validators.compose([Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚÑ ]*")])],
      apPaAct1: ['', Validators.compose([Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚÑ ]*")])],
      apMaAct1: ['', Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚ ]*")],
      genero1: [''],
      anioN: [''],
      alcaldia1: [''],
      col1: [''],
      cp1: [''],

      categ: ['', Validators.compose([ Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚ ]*")])],
      fechaEn: [''],
      fechaSal:[''],
      personaDes: ['', Validators.compose([ Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚ ]*")])],
      acc: [''],
      
      causa: [''],
      sueldoBas: new FormControl(''),
      
      periodoS: [''],
      sueldoEst: ['', Validators.compose([ Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")])],
      periodoSE: [''],
      tipoJ: [''],
      numHorasL: ['', Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
      salariosDeven: [''],
      otrosMontosCuantificados: ['']
    });
    this.form2= this.formBuilder.group({
      numDemandados: [''],
      numTrabajadores: [''],
      giro: [''],
      //datos gen deman
      fechaD: [''],
      tipoA: [''],
      nomAb1: ['', Validators.compose([ Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚÑ ]*")])],
      nomAb2: ['', Validators.compose([ Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚÑ ]*")])],
      nomAb3: ['', Validators.compose([ Validators.pattern("[a-zA-ZáéíóúÁÉÍÓÚÑ ]*")])]
    });

    this.checkLogin().then(result=>{
      if (result==true){
        this.traerDatos2();
      }
    });
  }

  checkLogin(){
    return new Promise((resolve) => {
      this.apiLogin.getGroup().then(result=>{
        if (result==null || result['grupo']!='Capturista'){
          this.router.navigate(['/login/']);
          resolve(false);
        }
        else{
          resolve(true);
        } 
      });
    });
  }

  idActorActual: any;

  asignarExp(){
    //console.log(this.usuario)
    
    var data = { 
      "username":this.usuario
    }
    return this.apiControl.asignarExpediente(data).then(result=>{
      //console.log(result);
      if(result["idExpediente"]==undefined){
        this.expedienteAsignado=result[0]["idExpediente"];
        this.mensaje="Tiene un expediente en proceso para capturar: "+ this.expedienteAsignado+"\n";
        this.myModal.toggle();
        this.expedienteAsignado=this.expedienteAsignado.split("_");
        //console.log(this.expedienteAsignado);
        this.junta = this.expedienteAsignado[0];
        this.anio = this.expedienteAsignado[2];
        this.expediente = this.expedienteAsignado[1];
        (document.getElementById("junta") as HTMLInputElement).value=this.expedienteAsignado[0];
        (document.getElementById("expediente") as HTMLInputElement).value=this.expedienteAsignado[1];
        (document.getElementById("anio") as HTMLInputElement).value=this.expedienteAsignado[2];
        


      }else{
        this.expedienteAsignado=result["idExpediente"];
        this.mensaje="Su expediente asignado para capturar es el "+ this.expedienteAsignado+"\n";
        this.expedienteAsignado=this.expedienteAsignado.split("_");
        this.junta = this.expedienteAsignado[0];
        this.anio = this.expedienteAsignado[2];
        this.expediente = this.expedienteAsignado[1];
        console.log(this.expedienteAsignado);
        (document.getElementById("junta") as HTMLInputElement).value=this.expedienteAsignado[0];
        (document.getElementById("expediente") as HTMLInputElement).value=this.expedienteAsignado[1];
        (document.getElementById("anio") as HTMLInputElement).value=this.expedienteAsignado[2];
        

      }
      
      //Imprime la lista de expedientes para fiscalizar y fiscalizados [[lista aFisc],0,[lista Fisc]]
    });
  }
  


  changeActorDir() {
    if ((document.getElementById("vacaciones") as HTMLInputElement).value == "0") {
      (document.getElementById("fechaInicialVac") as HTMLInputElement).disabled = false;
      (document.getElementById("razonPerVac") as HTMLInputElement).disabled = false;


    }
  }
  changeForMonto() {
    if ((document.getElementById("indemnizacionConst") as HTMLInputElement).checked) {
      (document.getElementById("montoIndemn") as HTMLInputElement).style.display = "block";
    }
    else {
      (document.getElementById("montoIndemn") as HTMLInputElement).style.display = "none";
    }
    if ((document.getElementById("indemnizacion20") as HTMLInputElement).checked) {
      (document.getElementById("montoIndemn20") as HTMLInputElement).style.display = "block";
    }
    else {
      (document.getElementById("montoIndemn20") as HTMLInputElement).style.display = "none";
    }
    if ((document.getElementById("salariosCaidos") as HTMLInputElement).checked) {
      (document.getElementById("montoSalariosCaidos") as HTMLInputElement).style.display = "block";
    }
    else {
      (document.getElementById("montoSalariosCaidos") as HTMLInputElement).style.display = "none";
    }
    if ((document.getElementById("primaAntiguedad") as HTMLInputElement).checked) {
      (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).style.display = "block";
    }
    else {
      (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).style.display = "none";
    }
  }

  changeMin() {
    this.minDateVac = ((document.getElementById('fechaInicialVac') as HTMLInputElement).value);
    this.minDateAg = ((document.getElementById('fechaInicialAguinaldo') as HTMLInputElement).value);
    this.minDateH = ((document.getElementById('fechaInicialHorasEx') as HTMLInputElement).value);
  }
  changeForVacaciones() {
    if ((document.getElementById('vacacionesRaz') as HTMLInputElement).value == "0") {
      
      (document.getElementById("vacaciones") as HTMLInputElement).style.display = "block";

    } else {
      (document.getElementById("vacaciones") as HTMLInputElement).style.display = "none";
      (document.getElementById('fechaInicialVac') as HTMLInputElement).value= "";
      (document.getElementById('fechaFinalVac') as HTMLInputElement).value= "";
      (document.getElementById('razonPerVac') as HTMLInputElement).value= "";
      
      
    }

  }
  changeForPrimaVacaciones() {
    if ((document.getElementById('primaVacacional') as HTMLInputElement).checked) {
      (document.getElementById("vacaciones2") as HTMLInputElement).style.display = "block";

    } else {
      (document.getElementById("vacaciones2") as HTMLInputElement).style.display = "none";
    }

  }
  changeForAguinaldo() {
    if ((document.getElementById('aguinaldoS') as HTMLInputElement).value == "0") {
      (document.getElementById("aguinaldo") as HTMLInputElement).style.display = "block";

    } else {
      (document.getElementById("aguinaldo") as HTMLInputElement).style.display = "none";
      (document.getElementById('fechaInicialAguinaldo') as HTMLInputElement).value= "";
      (document.getElementById('fechaFinalAguinaldo') as HTMLInputElement).value= "";
      (document.getElementById('razonAguinaldo') as HTMLInputElement).value= "";
    }

  }
  changeForHorasExtras() {
    if ((document.getElementById('horasExtrasS') as HTMLInputElement).value == "0") {
      (document.getElementById("horasExtras") as HTMLInputElement).style.display = "block";

    } else {
      (document.getElementById("horasExtras") as HTMLInputElement).style.display = "none";
      (document.getElementById('fechaInicialHorasExtras') as HTMLInputElement).value= "";
      (document.getElementById('fechaFinalHorasExtras') as HTMLInputElement).value= "";
      (document.getElementById('numHorasExtras') as HTMLInputElement).value= "";
    }

  }

  changeForTipoAbogado() {
    var ta = (document.getElementById("tipoAbogado") as HTMLInputElement).value;

    if (ta == "3") {
      (document.getElementById("despacho") as HTMLInputElement).disabled = true;
    }
    else {
      (document.getElementById("despacho") as HTMLInputElement).disabled = false;
    }
  }

  changeForOtraRazonEspecifica() {
    if ((document.getElementById("causa") as HTMLInputElement).value == "5") {

      (document.getElementById("causaEspecifica") as HTMLInputElement).disabled = false;

    } else {
      (document.getElementById("causaEspecifica") as HTMLInputElement).disabled = true;

    }

  }
  changeForAgregarTrabajadorBtn() {
    console.log(this.actualizarAct);
    //this.traerDatos();
    if ((document.getElementById("actoresSelect") as HTMLInputElement).value != "500" || (document.getElementById("actoresSelect") as HTMLInputElement).value != "501") {
      this.actualizarAct=false;
      this.agregarAct=true;
      
      
      if(this.trabajadores.length>0){
        var pk=(document.getElementById("actoresSelect") as HTMLInputElement).value;
        this.trabajadorAct=this.trabajadores[pk];
      
      (document.getElementById("alcaldia") as HTMLInputElement).value= this.trabajadores[pk]["alcaldia"];
            
      (document.getElementById("anioN") as HTMLInputElement).value= this.trabajadores[pk]["anioNacimiento"];
      (document.getElementById("wNombre") as HTMLInputElement).value= this.trabajadores[pk]["nombre"];
      (document.getElementById("wApellidoPaterno") as HTMLInputElement).value= this.trabajadores[pk]["apellidoPaterno"];
      (document.getElementById("wApellidoMaterno") as HTMLInputElement).value= this.trabajadores[pk]["apellidoMaterno"];
      (document.getElementById("cp") as HTMLInputElement).value= this.trabajadores[pk]["codigoPostal"];
      (document.getElementById("colonia") as HTMLInputElement).value= this.trabajadores[pk]["colonia"];
      (document.getElementById("genero") as HTMLInputElement).value= this.trabajadores[pk]["genero"];
      
      (document.getElementById("accion") as HTMLInputElement).value= this.trabajadores[pk]["accionPrincipal"];
      (document.getElementById("aguinaldoS") as HTMLInputElement).value= this.trabajadores[pk]["aguinaldo"]; 
      
      if((document.getElementById("aguinaldoS") as HTMLInputElement).value =="0"){
        
        (document.getElementById("aguinaldo") as HTMLInputElement).style.display = "block";
  
      }else{
        (document.getElementById("aguinaldo") as HTMLInputElement).style.display = "none";
       
      }
      (document.getElementById("categoria") as HTMLInputElement).value= this.trabajadores[pk]["categoria"];
      (document.getElementById("causa") as HTMLInputElement).value= this.trabajadores[pk]["causa"];
      if(this.trabajadores[pk]["causa"]=="5"){
        this.changeForOtraRazonEspecifica();
        (document.getElementById("causaEspecifica") as HTMLInputElement).value=this.trabajadores[pk]["causaEspecifica"];
      }
      (document.getElementById("cuotas") as HTMLInputElement).checked= this.trabajadores[pk]["cuotas"];
      (document.getElementById("fechaEntrada") as HTMLInputElement).value= this.trabajadores[pk]["fechaEntrada"];
      
      (document.getElementById("fechaFinalAguinaldo") as HTMLInputElement).value= this.trabajadores[pk]["fechaFinalAguinaldo"];
      (document.getElementById("fechaFinalHorasExtras") as HTMLInputElement).value= this.trabajadores[pk]["fechaFinalHorasExtra"];
      
      (document.getElementById("fechaFinalVac") as HTMLInputElement).value= this.trabajadores[pk]["fechaFinalVacaciones"];
      (document.getElementById("fechaInicialAguinaldo") as HTMLInputElement).value= this.trabajadores[pk]["fechaInicialAguinaldo"];
      (document.getElementById("fechaInicialHorasExtras") as HTMLInputElement).value= this.trabajadores[pk]["fechaInicialHorasExtra"];
      (document.getElementById("fechaInicialVac") as HTMLInputElement).value= this.trabajadores[pk]["fechaInicialVacaciones"];
      (document.getElementById("fechaSalida") as HTMLInputElement).value= this.trabajadores[pk]["fechaSalida"];
      (document.getElementById("horasExtrasS") as HTMLInputElement).value= this.trabajadores[pk]["horasExtra"];
      if((document.getElementById("horasExtrasS") as HTMLInputElement).value =="0"){
        (document.getElementById("horasExtras") as HTMLInputElement).style.display = "block";
        //
    
      }else{
        (document.getElementById("horasExtras") as HTMLInputElement).style.display = "none";
      }
      (document.getElementById("horasLaboradas") as HTMLInputElement).value= this.trabajadores[pk]["horasLaboradas"];
      (document.getElementById("indemnizacionConst") as HTMLInputElement).checked= this.trabajadores[pk]["indemConst"];
      if((document.getElementById("indemnizacionConst") as HTMLInputElement).checked){
        
        (document.getElementById("montoIndemn") as HTMLInputElement).style.display = "block";
  
      }else{
        (document.getElementById("montoIndemn") as HTMLInputElement).style.display = "none";
       
      }
      (document.getElementById("indemnizacion20") as HTMLInputElement).checked= this.trabajadores[pk]["indemConst20"];
      if((document.getElementById("indemnizacion20") as HTMLInputElement).checked){
        
        (document.getElementById("montoIndemn20") as HTMLInputElement).style.display = "block";
  
      }else{
        (document.getElementById("montoIndemn20") as HTMLInputElement).style.display = "none";
       
      }
      
      (document.getElementById("montoIndemnA") as HTMLInputElement).value= this.trabajadores[pk]["montoIndemnizacion"];
      (document.getElementById("montoIndemn20A") as HTMLInputElement).value= this.trabajadores[pk]["montoIndemnizacion20"];
      (document.getElementById("montoPrimaAntiguedadA") as HTMLInputElement).value= this.trabajadores[pk]["montoPrima"];

      
      (document.getElementById("montoPrimaVac") as HTMLInputElement).value= this.trabajadores[pk]["montoPrimaVacacional"];
      (document.getElementById("montoSalariosCaidosA") as HTMLInputElement).value= this.trabajadores[pk]["montoSalariosC"];
      (document.getElementById("nulidad") as HTMLInputElement).checked= this.trabajadores[pk]["nulidad"];
      (document.getElementById("numHorasExtras") as HTMLInputElement).value= this.trabajadores[pk]["numHorasExtra"];
      (document.getElementById("otrosMontosCuantificados") as HTMLInputElement).value= this.trabajadores[pk]["otrasPrestaciones"];
      (document.getElementById("periodoSueldo") as HTMLInputElement).value= this.trabajadores[pk]["periodicidadSueldoBase"];
      (document.getElementById("periodoSueldoEst") as HTMLInputElement).value= this.trabajadores[pk]["periodicidadSueldoEstadistico"];
      (document.getElementById("personaDespide") as HTMLInputElement).value= this.trabajadores[pk]["personaQueDespide"];
      (document.getElementById("porcentajePrimaVac") as HTMLInputElement).value= this.trabajadores[pk]["porcentajePrimaVacacional"];
      (document.getElementById("porProyecto") as HTMLInputElement).checked= this.trabajadores[pk]["porProyecto"];
      (document.getElementById("primaAntiguedad") as HTMLInputElement).checked= this.trabajadores[pk]["primaAnt"];
      if((document.getElementById("primaAntiguedad") as HTMLInputElement).checked){
        
        (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).style.display = "block";
  
      }else{
        (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).style.display = "none";
       
      }
      (document.getElementById("primaVacacional") as HTMLInputElement).checked= this.trabajadores[pk]["primaVacacional"];
      if((document.getElementById("primaVacacional") as HTMLInputElement).checked){
        
        (document.getElementById("vacaciones2") as HTMLInputElement).style.display = "block";
        
      }else{
        (document.getElementById("vacaciones2") as HTMLInputElement).style.display = "none";
        
      }
      (document.getElementById("razonAguinaldo") as HTMLInputElement).value= this.trabajadores[pk]["razonPerAguinaldo"];
      (document.getElementById("razonPerVac") as HTMLInputElement).value= this.trabajadores[pk]["razonPerVac"];
      (document.getElementById("reinstalacion") as HTMLInputElement).checked= this.trabajadores[pk]["reinstalacion"];
      (document.getElementById("salariosCaidos") as HTMLInputElement).checked= this.trabajadores[pk]["salariosCaidos"];
      if((document.getElementById("salariosCaidos") as HTMLInputElement).checked){
        
        (document.getElementById("montoSalariosCaidos") as HTMLInputElement).style.display = "block";
  
      }else{
        (document.getElementById("montoSalariosCaidos") as HTMLInputElement).style.display = "none";
       
      }
      (document.getElementById("salariosDeven") as HTMLInputElement).value= this.trabajadores[pk]["salariosDevengados"];
      (document.getElementById("sueldoBase") as HTMLInputElement).value= this.trabajadores[pk]["sueldoBase"];
      (document.getElementById("sueldoEst") as HTMLInputElement).value= this.trabajadores[pk]["sueldoEstadistico"];
      (document.getElementById("tipoJornada") as HTMLInputElement).value= this.trabajadores[pk]["tipoJornada"];
      (document.getElementById("trabConfianza") as HTMLInputElement).checked= this.trabajadores[pk]["trabajadorDeConfianza"];
      (document.getElementById("vacacionesRaz") as HTMLInputElement).value= this.trabajadores[pk]["vacaciones"];
      if((document.getElementById("vacacionesRaz") as HTMLInputElement).value =="0"){
        (document.getElementById("vacaciones") as HTMLInputElement).style.display = "block";
       
        //
    
      }else{
        (document.getElementById("vacaciones") as HTMLInputElement).style.display = "none";
        

      }
      }

      
    } else {
      this.actualizarAct=true;
      this.agregarAct=false;

    }

  }
  validacionesDemanda(){

     //primero validacion de que no manden vacios!
     if((document.getElementById("junta") as HTMLInputElement).value=="" ||
     (document.getElementById("junta") as HTMLInputElement).value==""
     || (document.getElementById("junta") as HTMLInputElement).value==""
     || (document.getElementById("junta") as HTMLInputElement).value==""
     || (document.getElementById("anio") as HTMLInputElement).value==""
     || (document.getElementById("expediente") as HTMLInputElement).value==""
     || (document.getElementById("numDemandados") as HTMLInputElement).value==""
     || (document.getElementById("abogado1") as HTMLInputElement).value==""
     || (document.getElementById("abogado2") as HTMLInputElement).value==""
     || (document.getElementById("abogado3") as HTMLInputElement).value==""
     || (document.getElementById("fechaAuto") as HTMLInputElement).value==""
     || (document.getElementById("giro") as HTMLInputElement).value==""
     || (document.getElementById("tipoAbogado") as HTMLInputElement).value==""){
      this.boolValidacionesRequiredDemanda=false;
     }else{
      this.boolValidacionesRequiredDemanda=true;

     }
    

    //despues validaciones especiales
    this.buttonBoolDem = true;
    if ((document.getElementById('tipoAbogado') as HTMLInputElement).value == "1" || (document.getElementById('tipoAbogado') as HTMLInputElement).value == "2") {
      if ((document.getElementById("despacho") as HTMLInputElement).value == "") {
        this.boolDespacho = false;

      } else {
        this.boolDespacho = true;
      }

    } else {
      this.boolDespacho = true;

    }
    if(!this.boolDespacho|| this.form2.invalid || !this.boolValidacionesRequiredDemanda){
      
      this.boolValidacionesDemanda = false;

    }else{
      
      this.boolValidacionesDemanda = true;
    }
  }
  validaciones() {
    this.buttonBool = true;

    //validaciones para el required
    if( (document.getElementById("alcaldia") as HTMLInputElement).value==""
    || (document.getElementById("wNombre") as HTMLInputElement).value==""
    || (document.getElementById("wApellidoPaterno") as HTMLInputElement).value==""
    || (document.getElementById("wApellidoMaterno") as HTMLInputElement).value==""

    || (document.getElementById("colonia") as HTMLInputElement).value==""
    || (document.getElementById("genero") as HTMLInputElement).value==""
    || (document.getElementById("accion") as HTMLInputElement).value==""
    || (document.getElementById("aguinaldoS") as HTMLInputElement).value=="" //checar en la base hay error
    || (document.getElementById("categoria") as HTMLInputElement).value==""
    || (document.getElementById("causa") as HTMLInputElement).value==""
    || (document.getElementById("fechaEntrada") as HTMLInputElement).value==""
    
    || (document.getElementById("fechaSalida") as HTMLInputElement).value==""
    || (document.getElementById("horasExtrasS") as HTMLInputElement).value==""
    
    
    || (document.getElementById("periodoSueldo") as HTMLInputElement).value==""
    || (document.getElementById("periodoSueldoEst") as HTMLInputElement).value==""
    || (document.getElementById("personaDespide") as HTMLInputElement).value==""
    
    || (document.getElementById("sueldoEst") as HTMLInputElement).value==""
    || (document.getElementById("tipoJornada") as HTMLInputElement).value==""){
      this.boolValidacionesRequiredTrabajador= false;
    }else{
      this.boolValidacionesRequiredTrabajador= true;
    }

    
   

    // validaciones especiales
    if ((document.getElementById('horasExtrasS') as HTMLInputElement).value == "0") {
      if ((document.getElementById("fechaInicialHorasExtras") as HTMLInputElement).value == "") {
        this.boolfechaIniHorasExtras = false;

      } else {
        this.boolfechaIniHorasExtras = true;
      }

      if ((document.getElementById("fechaFinalHorasExtras") as HTMLInputElement).value == "") {
        this.boolfechaFinHorasExtras = false;

      } else {
        this.boolfechaFinHorasExtras = true;

      }
      if ((document.getElementById("numHorasExtras") as HTMLInputElement).value == "") {
        this.boolNumHorasExtras = false;

      }
      else {
        this.boolNumHorasExtras = true;

      }
    } else {
      this.boolfechaFinHorasExtras = true;
      this.boolfechaIniHorasExtras = true;
      this.boolNumHorasExtras = true;


    }
    if ((document.getElementById('aguinaldoS') as HTMLInputElement).value == "0") {
      if ((document.getElementById("fechaInicialAguinaldo") as HTMLInputElement).value == "") {
        this.boolfechaIniAguinaldo = false;

      } else {
        this.boolfechaIniAguinaldo = true;
      }

      if ((document.getElementById("fechaFinalAguinaldo") as HTMLInputElement).value == "") {
        this.boolfechaFinAguinaldo = false;

      } else {
        this.boolfechaFinAguinaldo = true;

      }
      if ((document.getElementById("razonAguinaldo") as HTMLInputElement).value == "") {
        this.boolRazonAguinaldo = false;

      }
      else {
        this.boolRazonAguinaldo = true;

      }
    } else {
      this.boolfechaFinHorasExtras = true;
      this.boolfechaIniHorasExtras = true;
      this.boolNumHorasExtras = true;


    }

    if ((document.getElementById('vacacionesRaz') as HTMLInputElement).checked) {
      if ((document.getElementById("fechaInicialVac") as HTMLInputElement).value == "") {
        this.boolFechaInicialVac = false;

      } else {
        this.boolFechaInicialVac = true;
      }

      if ((document.getElementById("fechaFinalVac") as HTMLInputElement).value == "") {
        this.boolfechaFinalVac = false;

      } else {
        this.boolfechaFinalVac = true;


      }
      if ((document.getElementById("razonPerVacaciones") as HTMLInputElement).value == "") {
        this.boolRazonVac = false;

      } else {
        this.boolRazonVac = true;
      }

    } else {
      this.boolRazonVac = true;
      this.boolfechaFinalVac = true;
      this.boolFechaInicialVac = true;



    }
    if ((document.getElementById('primaVacacional') as HTMLInputElement).checked) {
      if ((document.getElementById("porcentajePrimaVac") as HTMLInputElement).value == "") {
        this.boolPorcentajePrimaVac = false;

      } else {
        this.boolPorcentajePrimaVac = true;
      }

      if ((document.getElementById("montoPrimaVac") as HTMLInputElement).value == "") {
        this.boolMontoPrimaVac = false;

      } else {
        this.boolMontoPrimaVac = true;

      }



    } else {
      this.boolMontoPrimaVac = true;
      this.boolPorcentajePrimaVac = true;



    }
    if ((document.getElementById('primaAntiguedad') as HTMLInputElement).checked) {
      if ((document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).value == "") {
        this.boolMontoPrimaAntiguedad = false;

      } else {
        this.boolMontoPrimaAntiguedad = true;
      }

    } else {
      this.boolMontoPrimaAntiguedad = true;

    }
    if ((document.getElementById('salariosCaidos') as HTMLInputElement).checked) {
      if ((document.getElementById("montoSalariosCaidos") as HTMLInputElement).value == "") {
        this.boolMontoSalariosCaidos = false;

      } else {
        this.boolMontoSalariosCaidos = true;
      }

    } else {
      this.boolMontoSalariosCaidos = true;

    }
    if ((document.getElementById('indemnizacion20') as HTMLInputElement).checked) {
      if ((document.getElementById("montoIndemn20") as HTMLInputElement).value == "") {
        this.boolIndemn20 = false;

      } else {
        this.boolIndemn20 = true;
      }

    } else {
      this.boolIndemn20 = true;

    }

    if ((document.getElementById('indemnizacionConst') as HTMLInputElement).checked) {
      if ((document.getElementById("montoIndemn") as HTMLInputElement).value == "") {
        this.boolIndemn = false;

      } else {
        this.boolIndemn = true;
      }

    } else {
      this.boolIndemn = true;

    }
    if ((document.getElementById('causa') as HTMLInputElement).value == "5") {
      if ((document.getElementById("causaEspecifica") as HTMLInputElement).value == "") {
        this.boolCausa = false;

      } else {
        this.boolCausa = true;
      }

    } else {
      this.boolCausa = true;

    }
    //console.log(!this.boolNumHorasExtras +" ,"+ !this.boolfechaIniAguinaldo +" ,"+ !this.boolfechaFinAguinaldo +" ,"+ !this.boolRazonAguinaldo
    //      +" ,"+ !this.boolPorcentajePrimaVac+" ,"+ !this.boolMontoPrimaVac+" ,"+ !this.boolFechaInicialVac+" ,"+ !this.boolfechaFinalVac
     //     +" ,"+ !this.boolRazonVac+" ,"+ !this.boolMontoPrimaAntiguedad+" ,"+ !this.boolMontoSalariosCaidos+" ,"+ !this.boolIndemn20
     //     +" ,"+ !this.boolIndemn+" ,"+ !this.boolCausa +" ,"+this.form.invalid+" ,"+ !this.boolValidacionesRequiredTrabajador);
   
    if(!this.boolNumHorasExtras || !this.boolfechaIniAguinaldo! || !this.boolfechaFinAguinaldo 
        || !this.boolFechaInicialVac|| !this.boolfechaFinalVac
        || !this.boolCausa ||this.form.invalid || !this.boolValidacionesRequiredTrabajador){
          this.boolValidacionesTrabajador=false;
          
        }
        else{
          this.boolValidacionesTrabajador=true;
        }
   

  }
  borrar(){
    this.form.reset();
    this.actualizarAct=true; 
      this.agregarAct=false;
      (document.getElementById('vacacionesRaz') as HTMLInputElement).value = "1";
      this.changeForVacaciones();
      (document.getElementById("vacaciones2") as HTMLInputElement).style.display = "none";
      (document.getElementById('aguinaldoS') as HTMLInputElement).value = "1";
      this.changeForAguinaldo();
      (document.getElementById('horasExtrasS') as HTMLInputElement).value = "1";
      this.changeForHorasExtras();
      this.changeForMonto();
    //para guardar las casillitas de los montos
      
        (document.getElementById("montoIndemn") as HTMLInputElement).value = "";
      
        (document.getElementById("montoIndemn20") as HTMLInputElement).value= "";
      
        (document.getElementById("montoSalariosCaidos") as HTMLInputElement).value = "";
      
        (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).value= "";

        (document.getElementById("montoIndemn") as HTMLInputElement).style.display = "none";
      
        (document.getElementById("montoIndemn20") as HTMLInputElement).style.display = "none";
      
        (document.getElementById("montoSalariosCaidos") as HTMLInputElement).style.display = "none";
      
        (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).style.display = "none";
    

  }
  borrarDemanda(){
    this.form2.reset();
    this.actualizarAct=true;
      this.agregarAct=false;
  }
  borrarTodo(){
    this.form.reset();
    this.form2.reset();
    this.actualizarAct=true;
    this.agregarAct=false;
    
  }
  traerDatos2() {
    console.log("traerDatos")
    var idExp;

    this.asignarExp().then(result=>{
      console.log(this.today);
      
      idExp = this.junta + "_" + this.expediente + "_" + this.anio;
      console.log(idExp);
      
      return this.apiLigas.getAll(idExp);
    }).then((res: Array<JSON>)=>{
      console.log(res);
      if (res.length>0){
        this.pdfSource = this.dom.bypassSecurityTrustResourceUrl(res[0]['liga']);
      }
      else{
        //console.log('No se ha subido el pdf');
        this.mensaje="No se ha subido el pdf. "
      }
      return this.apiDemanda.getAll(idExp);
    }).then((res: Array<JSON>) => {
        if(res.length>0){
          this.demandaExistente= true;
          this.demanda = res[0];
          console.log(this.demanda);
          (document.getElementById("numDemandados") as HTMLInputElement).value= this.demanda["numDemandados"];
          (document.getElementById("numTrabajadores") as HTMLInputElement).value= this.demanda["numActores"];
         
          (document.getElementById("codemanda") as HTMLInputElement).checked= this.demanda["codemanda"];
          (document.getElementById("prevencion") as HTMLInputElement).checked= this.demanda["prevencion"];
          (document.getElementById("abogado1") as HTMLInputElement).value= this.demanda["abogado1"];
          (document.getElementById("abogado2") as HTMLInputElement).value= this.demanda["abogado2"];
          (document.getElementById("abogado3") as HTMLInputElement).value= this.demanda["abogado3"];
          (document.getElementById("despacho") as HTMLInputElement).value= this.demanda["despacho"];
          (document.getElementById("fechaAuto") as HTMLInputElement).value= this.demanda["fechaDemanda"];
          (document.getElementById("giro") as HTMLInputElement).value= this.demanda["giro"];
          
          (document.getElementById("observaciones") as HTMLInputElement).value= this.demanda["observaciones"];

          (document.getElementById("reclutamiento") as HTMLInputElement).checked= this.demanda["reclutamiento"];
          (document.getElementById("tipoAbogado") as HTMLInputElement).value= this.demanda["tipoAbogado"];
          if(this.demanda["tipoAbogado"]==3){
            (document.getElementById("despacho") as HTMLInputElement).disabled = true;
          }
          

        }else{
          this.mensaje=this.mensaje+"Esta demanda no tiene datos capturados.      \n";
          
        }
        return  this.apiTrabajador.getAll(idExp)
        .then((res: Array<JSON>) => {
          console.log(res);
          if(res.length>0){
            this.trabajadores = res;
            console.log(this.trabajadores);
            console.log(this.trabajadores[0]["pk"]);
            //document.getElementById("numDemandados") as HTMLInputElement).value= this.demanda["numDemandados"];
            
          }else{this.mensaje=this.mensaje+"\n No se tienen trabajadores registrados."
                this.myModal.toggle();
                
                }
          
          });
        });


        
   


  }
  traerDatos() {
    
    this.junta = (document.getElementById("junta") as HTMLInputElement).value;
    this.anio = (document.getElementById("anio") as HTMLInputElement).value;
    this.expediente = (document.getElementById("expediente") as HTMLInputElement).value;
    console.log("traerDatos")
    var idExp;
    idExp = this.junta + "_" + this.expediente + "_" + this.anio;
    this.apiDemanda.getAll(idExp).then((res: Array<JSON>) => {
        if(res.length>0){
          this.demandaExistente= true;
          this.demanda = res[0];
          console.log(this.demanda);
          (document.getElementById("numDemandados") as HTMLInputElement).value= this.demanda["numDemandados"];
          (document.getElementById("numTrabajadores") as HTMLInputElement).value= this.demanda["numActores"];
         
          (document.getElementById("codemanda") as HTMLInputElement).checked= this.demanda["codemanda"];
          (document.getElementById("prevencion") as HTMLInputElement).checked= this.demanda["prevencion"];
          (document.getElementById("abogado1") as HTMLInputElement).value= this.demanda["abogado1"];
          (document.getElementById("abogado2") as HTMLInputElement).value= this.demanda["abogado2"];
          (document.getElementById("abogado3") as HTMLInputElement).value= this.demanda["abogado3"];
          (document.getElementById("despacho") as HTMLInputElement).value= this.demanda["despacho"];
          (document.getElementById("fechaAuto") as HTMLInputElement).value= this.demanda["fechaDemanda"];
          (document.getElementById("giro") as HTMLInputElement).value= this.demanda["giro"];
          
          (document.getElementById("observaciones") as HTMLInputElement).value= this.demanda["observaciones"];

          (document.getElementById("reclutamiento") as HTMLInputElement).checked= this.demanda["reclutamiento"];
          (document.getElementById("tipoAbogado") as HTMLInputElement).value= this.demanda["tipoAbogado"];
          if(this.demanda["tipoAbogado"]==3){
            (document.getElementById("despacho") as HTMLInputElement).disabled = true;
          }
          

        }else{
          this.mensaje=this.mensaje+"Esta demanda no tiene datos capturados.      \n";
          
        }
        return  this.apiTrabajador.getAll(idExp)
        .then((res: Array<JSON>) => {
          console.log(res);
          if(res.length>0){
            this.trabajadores = res;
            console.log(this.trabajadores);
            console.log(this.trabajadores[0]["pk"]);
            //document.getElementById("numDemandados") as HTMLInputElement).value= this.demanda["numDemandados"];
            
          }else{this.mensaje=this.mensaje+"\n No se tienen trabajadores registrados."
                this.myModal.toggle();
                
                }
          
          });
        });
  }
  //
  upperCase(obj) {
    for (var key in obj) {
        if (obj[key] !== null && typeof obj[key] === "object") {
            this.upperCase(obj[key]);
        } else if (obj[key] !== null && typeof obj[key] === "string") {
            obj[key] = obj[key].toUpperCase();
        }
    }
}

  demandaDatos() {
    this.junta = (document.getElementById("junta") as HTMLInputElement).value;
    this.anio = (document.getElementById("anio") as HTMLInputElement).value;
    this.expediente = (document.getElementById("expediente") as HTMLInputElement).value;
    var numDemandados = (document.getElementById("numDemandados") as HTMLInputElement).value;
    var numActores = (document.getElementById("numTrabajadores") as HTMLInputElement).value;
    var codemandaSars = (document.getElementById("codemanda") as HTMLInputElement).checked;
    var prevencion = (document.getElementById("prevencion") as HTMLInputElement).checked;
    var abogado1 = (document.getElementById("abogado1") as HTMLInputElement).value;
    var abogado2 = (document.getElementById("abogado2") as HTMLInputElement).value;
    var abogado3 = (document.getElementById("abogado3") as HTMLInputElement).value;
    var despacho = (document.getElementById("despacho") as HTMLInputElement).value;
    var fechaDemanda = (document.getElementById("fechaAuto") as HTMLInputElement).value;
    var giro = (document.getElementById("giro") as HTMLInputElement).value;
    var observaciones = (document.getElementById("observaciones") as HTMLInputElement).value;

    var reclutamiento = (document.getElementById("reclutamiento") as HTMLInputElement).checked;
    var tipoAbogado = (document.getElementById("tipoAbogado") as HTMLInputElement).value;
    var datosDemanda = {

      "junta": this.junta,
      "anio": this.anio,
      "expediente": this.expediente,
      "abogado1": abogado1,
      "abogado2": abogado2,
      "abogado3": abogado3,
      "codemanda": codemandaSars,
      "despacho": despacho,
      "fechaDemanda": fechaDemanda,
      "giro": giro,
      "numDemandados": numDemandados,
      "numActores": numActores,
      "observaciones": observaciones,
      "prevencion": prevencion,
      "reclutamiento": reclutamiento,
      "idExpediente": this.junta + "_" +this.expediente + "_" + this.anio ,
      "tipoAbogado": tipoAbogado
    }
    this.validacionesDemanda();
    this.upperCase(datosDemanda);
    //console.log(datosDemanda);
    
    if(this.boolValidacionesDemanda){
      this.uploadDemanda(datosDemanda);
      this.mensaje="Demanda actualizada correctamente."
      this.myModal.toggle();
      this.demanda=datosDemanda;
    }else{
      //console.log("errorDem")
      this.mensaje="Hay campos vacíos por favor revise los datos ingresados.";
      this.myModal.toggle();
    }

    

  }
  agregarTrabajador() {
    this.junta = (document.getElementById("junta") as HTMLInputElement).value;
    this.anio = (document.getElementById("anio") as HTMLInputElement).value;
    this.expediente = (document.getElementById("expediente") as HTMLInputElement).value;
    var alcaldia = (document.getElementById("alcaldia") as HTMLInputElement).value;
    var anioNacimiento = (document.getElementById("anioN") as HTMLInputElement).value;
   
    var nombre = (document.getElementById("wNombre") as HTMLInputElement).value;
    var apellidoPaterno = (document.getElementById("wApellidoPaterno") as HTMLInputElement).value;
    var apellidoMaterno = (document.getElementById("wApellidoMaterno") as HTMLInputElement).value;
    var codigoPostal = (document.getElementById("cp") as HTMLInputElement).value;
    var colonia = (document.getElementById("colonia") as HTMLInputElement).value;
    var genero = (document.getElementById("genero") as HTMLInputElement).value;
    
    var accionPrincipal = (document.getElementById("accion") as HTMLInputElement).value;
    var aguinaldo = (document.getElementById("aguinaldoS") as HTMLInputElement).value; //checar en la base hay error
    var categoria = (document.getElementById("categoria") as HTMLInputElement).value;
    var causa = (document.getElementById("causa") as HTMLInputElement).value;
    var causaEspecifica= (document.getElementById("causaEspecifica") as HTMLInputElement).value;
    var cuotas = (document.getElementById("cuotas") as HTMLInputElement).checked;
    var fechaEntrada = (document.getElementById("fechaEntrada") as HTMLInputElement).value;
    
    var fechaFinalAguinaldo = (document.getElementById("fechaFinalAguinaldo") as HTMLInputElement).value;
    var fechaFinalHorasExtra = (document.getElementById("fechaFinalHorasExtras") as HTMLInputElement).value;
    var fechaFinalVacaciones = (document.getElementById("fechaFinalVac") as HTMLInputElement).value;
    var fechaInicialAguinaldo = (document.getElementById("fechaInicialAguinaldo") as HTMLInputElement).value;
    var fechaInicialHorasExtra = (document.getElementById("fechaInicialHorasExtras") as HTMLInputElement).value;
    var fechaInicialVacaciones = (document.getElementById("fechaInicialVac") as HTMLInputElement).value;
    var fechaSalida = (document.getElementById("fechaSalida") as HTMLInputElement).value;
    var horasExtra = (document.getElementById("horasExtrasS") as HTMLInputElement).value;
    var horasLaboradas = (document.getElementById("horasLaboradas") as HTMLInputElement).value;
    if(horasLaboradas==""){
      horasLaboradas=null
    }
    var indemConst = (document.getElementById("indemnizacionConst") as HTMLInputElement).checked;
    var indemConst20 = (document.getElementById("indemnizacion20") as HTMLInputElement).checked;
    var montoIndemnizacion = (document.getElementById("montoIndemnA") as HTMLInputElement).value;
    var montoIndemnizacion20 = (document.getElementById("montoIndemn20A") as HTMLInputElement).value;
    var montoPrima = (document.getElementById("montoPrimaAntiguedadA") as HTMLInputElement).value;
    var montoPrimaVacacional = (document.getElementById("montoPrimaVac") as HTMLInputElement).value;
    var montoSalariosC = (document.getElementById("montoSalariosCaidosA") as HTMLInputElement).value;
    var nulidad = (document.getElementById("nulidad") as HTMLInputElement).checked;
    var numHorasExtra = (document.getElementById("numHorasExtras") as HTMLInputElement).value; 
    
    var otrasPrestaciones = (document.getElementById("otrosMontosCuantificados") as HTMLInputElement).value;
    if(otrasPrestaciones==""){
      otrasPrestaciones=null
    }
    var periodicidadSueldoBase = (document.getElementById("periodoSueldo") as HTMLInputElement).value;
    var periodicidadSueldoEstadistico = (document.getElementById("periodoSueldoEst") as HTMLInputElement).value;
    var personaQueDespide = (document.getElementById("personaDespide") as HTMLInputElement).value;
    var porcentajePrimaVacacional = (document.getElementById("porcentajePrimaVac") as HTMLInputElement).value;
    var porProyecto = (document.getElementById("porProyecto") as HTMLInputElement).checked;
    var primaAnt = (document.getElementById("primaAntiguedad") as HTMLInputElement).checked;
    var primaVacacional = (document.getElementById("primaVacacional") as HTMLInputElement).checked;
    var razonPerAguinaldo = (document.getElementById("razonAguinaldo") as HTMLInputElement).value;
    if(razonPerAguinaldo==""){
      razonPerAguinaldo=null
    }
    var razonPerVac = (document.getElementById("razonPerVac") as HTMLInputElement).value;
    if(razonPerVac==""){
      razonPerVac=null
    }
    var reinstalacion = (document.getElementById("reinstalacion") as HTMLInputElement).checked;
    var salariosCaidos = (document.getElementById("salariosCaidos") as HTMLInputElement).checked;
    var salariosDevengados = (document.getElementById("salariosDeven") as HTMLInputElement).value;
    if(salariosDevengados==""){
      salariosDevengados=null
    }
    var sueldoBase = (document.getElementById("sueldoBase") as HTMLInputElement).value;
    
    if(sueldoBase==""){
      sueldoBase=null
    }
    var sueldoEstadistico = (document.getElementById("sueldoEst") as HTMLInputElement).value;
    var tipoJornada = (document.getElementById("tipoJornada") as HTMLInputElement).value;
    var trabajadorDeConfianza = (document.getElementById("trabConfianza") as HTMLInputElement).checked;
    var vacaciones = (document.getElementById("vacacionesRaz") as HTMLInputElement).value;
    //datos del trabajador=

    if (indemConst == false || montoIndemnizacion=="") {
      montoIndemnizacion = null;
    }
    if (indemConst20 == false || montoIndemnizacion20=="") {
      montoIndemnizacion20 = null;
    }
    if (primaAnt == false || montoPrima=="") {
      montoPrima = null;
    }
    if (salariosCaidos == false || montoSalariosC=="") {
      montoSalariosC = null;
    }
    if (primaVacacional == false) {
      porcentajePrimaVacacional = null;
      montoPrimaVacacional= null;
    }
    if (porcentajePrimaVacacional == "") {
      porcentajePrimaVacacional = null;
      
    }
    if (montoPrimaVacacional == "") {
      
      montoPrimaVacacional= null;
    }
    if (aguinaldo == "1") {
      razonPerAguinaldo = null;
    }
    if (razonPerAguinaldo == "") {
      razonPerAguinaldo = null;
    }
    if (vacaciones == "1") {
      razonPerVac = null;
    }
    if (razonPerVac == "") {
      razonPerVac = null;
    }



    var datosTrabajador = {
      'alcaldia': alcaldia,
      'anioNacimiento': anioNacimiento,
      'nombre': nombre,
      'apellidoPaterno': apellidoPaterno,
      'apellidoMaterno': apellidoMaterno,
      'codigoPostal': codigoPostal,
      'colonia': colonia,
      'genero': genero,
      "accionPrincipal": accionPrincipal,
      "aguinaldo": aguinaldo,
      "categoria": categoria,
      "causa": causa,
      "causaEspecifica": causaEspecifica,
      "cuotas": cuotas,
      "fechaEntrada": fechaEntrada,
      "fechaFinalAguinaldo": fechaFinalAguinaldo,
      "fechaFinalHorasExtra": fechaFinalHorasExtra,
      "fechaFinalVacaciones": fechaFinalVacaciones,
      "fechaInicialAguinaldo": fechaInicialAguinaldo,
      "fechaInicialHorasExtra": fechaInicialHorasExtra,
      "fechaInicialVacaciones": fechaInicialVacaciones,
      "fechaSalida": fechaSalida,
      "horasExtra": horasExtra,
      "horasLaboradas": horasLaboradas,
      "indemConst": indemConst,
      "indemConst20": indemConst20,
      "montoIndemnizacion": montoIndemnizacion,
      "montoIndemnizacion20": montoIndemnizacion20,
      "montoPrima": montoPrima,
      "montoPrimaVacacional": montoPrimaVacacional,
      "montoSalariosC": montoSalariosC,
      "nulidad": nulidad,
      "numHorasExtra": numHorasExtra,
      "otrasPrestaciones": otrasPrestaciones,
      "periodicidadSueldoBase": periodicidadSueldoBase,
      "periodicidadSueldoEstadistico": periodicidadSueldoEstadistico,
      "personaQueDespide": personaQueDespide,
      "porcentajePrimaVacacional": porcentajePrimaVacacional,
      "porProyecto": porProyecto,
      "primaAnt": primaAnt,
      "primaVacacional": primaVacacional,
      "razonPerAguinaldo": razonPerAguinaldo,
      "razonPerVac": razonPerVac,
      "reinstalacion": reinstalacion,
      "salariosCaidos": salariosCaidos,
      "salariosDevengados": salariosDevengados,
      "sueldoBase": sueldoBase,
      "sueldoEstadistico": sueldoEstadistico,
      "tipoJornada": tipoJornada,
      "trabajadorDeConfianza": trabajadorDeConfianza,
      "idExpediente": this.junta + "_" +this.expediente  + "_" +this.anio ,
      "vacaciones": vacaciones


    }
    this.validaciones();
    this.upperCase(datosTrabajador);
    console.log(datosTrabajador)
    if(this.boolValidacionesTrabajador){
      this.uploadTrabajador(datosTrabajador);
      this.mensaje="Trabajador actualizado correctamente.";
      this.myModal.toggle();
      
       
      this.actualizarAct=true;
      this.agregarAct=true;
      
    }else{
      //console.log("Datos incorrectos");
      console.log("error");
      this.mensaje="Hay campos vacíos por favor revise los datos ingresados.";
      this.myModal.toggle();
      
    }
    
    this.traerDatos();


  }
  actualizarTrabajador() {
    this.junta = (document.getElementById("junta") as HTMLInputElement).value;
    this.anio = (document.getElementById("anio") as HTMLInputElement).value;
    this.expediente = (document.getElementById("expediente") as HTMLInputElement).value;
    var alcaldia = (document.getElementById("alcaldia") as HTMLInputElement).value;
    var anioNacimiento = (document.getElementById("anioN") as HTMLInputElement).value;
   
    var nombre = (document.getElementById("wNombre") as HTMLInputElement).value;
    var apellidoPaterno = (document.getElementById("wApellidoPaterno") as HTMLInputElement).value;
    var apellidoMaterno = (document.getElementById("wApellidoMaterno") as HTMLInputElement).value;
    var codigoPostal = (document.getElementById("cp") as HTMLInputElement).value;
    var colonia = (document.getElementById("colonia") as HTMLInputElement).value;
    var genero = (document.getElementById("genero") as HTMLInputElement).value;
    
    var accionPrincipal = (document.getElementById("accion") as HTMLInputElement).value;
    var aguinaldo = (document.getElementById("aguinaldoS") as HTMLInputElement).value; //checar en la base hay error
    var categoria = (document.getElementById("categoria") as HTMLInputElement).value;
    var causa = (document.getElementById("causa") as HTMLInputElement).value;
    var causaEspecifica= (document.getElementById("causaEspecifica") as HTMLInputElement).value;
    var cuotas = (document.getElementById("cuotas") as HTMLInputElement).checked;
    var fechaEntrada = (document.getElementById("fechaEntrada") as HTMLInputElement).value;
    
    var fechaFinalAguinaldo = (document.getElementById("fechaFinalAguinaldo") as HTMLInputElement).value;
    var fechaFinalHorasExtra = (document.getElementById("fechaFinalHorasExtras") as HTMLInputElement).value;
    var fechaFinalVacaciones = (document.getElementById("fechaFinalVac") as HTMLInputElement).value;
    var fechaInicialAguinaldo = (document.getElementById("fechaInicialAguinaldo") as HTMLInputElement).value;
    var fechaInicialHorasExtra = (document.getElementById("fechaInicialHorasExtras") as HTMLInputElement).value;
    var fechaInicialVacaciones = (document.getElementById("fechaInicialVac") as HTMLInputElement).value;
    var fechaSalida = (document.getElementById("fechaSalida") as HTMLInputElement).value;
    var horasExtra = (document.getElementById("horasExtrasS") as HTMLInputElement).value;
    var horasLaboradas = (document.getElementById("horasLaboradas") as HTMLInputElement).value;
    if(horasLaboradas==""){
      horasLaboradas=null
    }
    var indemConst = (document.getElementById("indemnizacionConst") as HTMLInputElement).checked;
    var indemConst20 = (document.getElementById("indemnizacion20") as HTMLInputElement).checked;
    var montoIndemnizacion = (document.getElementById("montoIndemnA") as HTMLInputElement).value;
    var montoIndemnizacion20 = (document.getElementById("montoIndemn20A") as HTMLInputElement).value;
    var montoPrima = (document.getElementById("montoPrimaAntiguedadA") as HTMLInputElement).value;
    var montoPrimaVacacional = (document.getElementById("montoPrimaVac") as HTMLInputElement).value;
    var montoSalariosC = (document.getElementById("montoSalariosCaidosA") as HTMLInputElement).value;
    var nulidad = (document.getElementById("nulidad") as HTMLInputElement).checked;
    var numHorasExtra = (document.getElementById("numHorasExtras") as HTMLInputElement).value; 
    
    var otrasPrestaciones = (document.getElementById("otrosMontosCuantificados") as HTMLInputElement).value;
    if(otrasPrestaciones==""){
      otrasPrestaciones=null
    }
    var periodicidadSueldoBase = (document.getElementById("periodoSueldo") as HTMLInputElement).value;
    var periodicidadSueldoEstadistico = (document.getElementById("periodoSueldoEst") as HTMLInputElement).value;
    var personaQueDespide = (document.getElementById("personaDespide") as HTMLInputElement).value;
    var porcentajePrimaVacacional = (document.getElementById("porcentajePrimaVac") as HTMLInputElement).value;
    var porProyecto = (document.getElementById("porProyecto") as HTMLInputElement).checked;
    var primaAnt = (document.getElementById("primaAntiguedad") as HTMLInputElement).checked;
    var primaVacacional = (document.getElementById("primaVacacional") as HTMLInputElement).checked;
    var razonPerAguinaldo = (document.getElementById("razonAguinaldo") as HTMLInputElement).value;
    if(razonPerAguinaldo==""){
      razonPerAguinaldo=null
    }
    var razonPerVac = (document.getElementById("razonPerVac") as HTMLInputElement).value;
    if(razonPerVac==""){
      razonPerVac=null
    }
    var reinstalacion = (document.getElementById("reinstalacion") as HTMLInputElement).checked;
    var salariosCaidos = (document.getElementById("salariosCaidos") as HTMLInputElement).checked;
    var salariosDevengados = (document.getElementById("salariosDeven") as HTMLInputElement).value;
    if(salariosDevengados==""){
      salariosDevengados=null
    }
    var sueldoBase = (document.getElementById("sueldoBase") as HTMLInputElement).value;
    
    if(sueldoBase==""){
      sueldoBase=null
    }
    var sueldoEstadistico = (document.getElementById("sueldoEst") as HTMLInputElement).value;
    var tipoJornada = (document.getElementById("tipoJornada") as HTMLInputElement).value;
    var trabajadorDeConfianza = (document.getElementById("trabConfianza") as HTMLInputElement).checked;
    var vacaciones = (document.getElementById("vacacionesRaz") as HTMLInputElement).value;
    //datos del trabajador=

    if (indemConst == false || montoIndemnizacion=="") {
      montoIndemnizacion = null;
    }
    if (indemConst20 == false || montoIndemnizacion20=="") {
      montoIndemnizacion20 = null;
    }
    if (primaAnt == false || montoPrima=="") {
      montoPrima = null;
    }
    if (salariosCaidos == false || montoSalariosC=="") {
      montoSalariosC = null;
    }
    if (primaVacacional == false) {
      porcentajePrimaVacacional = null;
      montoPrimaVacacional= null;
    }
    if (porcentajePrimaVacacional == "") {
      porcentajePrimaVacacional = null;
      
    }
    if (montoPrimaVacacional == "") {
      
      montoPrimaVacacional= null;
    }
    if (aguinaldo == "1") {
      razonPerAguinaldo = null;
    }
    if (razonPerAguinaldo == "") {
      razonPerAguinaldo = null;
    }
    if (vacaciones == "1") {
      razonPerVac = null;
    }
    if (razonPerVac == "") {
      razonPerVac = null;
    }

    var datosTrabajador = {
      'alcaldia': alcaldia,
      'anioNacimiento': anioNacimiento,
      'nombre': nombre,
      'apellidoPaterno': apellidoPaterno,
      'apellidoMaterno': apellidoMaterno,
      'codigoPostal': codigoPostal,
      'colonia': colonia,
      'genero': genero,
      "accionPrincipal": accionPrincipal,
      "aguinaldo": aguinaldo,
      "categoria": categoria,
      "causa": causa,
      "causaEspecifica": causaEspecifica,
      "cuotas": cuotas,
      "fechaEntrada": fechaEntrada,
      "fechaFinalAguinaldo": fechaFinalAguinaldo,
      "fechaFinalHorasExtra": fechaFinalHorasExtra,
      "fechaFinalVacaciones": fechaFinalVacaciones,
      "fechaInicialAguinaldo": fechaInicialAguinaldo,
      "fechaInicialHorasExtra": fechaInicialHorasExtra,
      "fechaInicialVacaciones": fechaInicialVacaciones,
      "fechaSalida": fechaSalida,
      "horasExtra": horasExtra,
      "horasLaboradas": horasLaboradas,
      "indemConst": indemConst,
      "indemConst20": indemConst20,
      "montoIndemnizacion": montoIndemnizacion,
      "montoIndemnizacion20": montoIndemnizacion20,
      "montoPrima": montoPrima,
      "montoPrimaVacacional": montoPrimaVacacional,
      "montoSalariosC": montoSalariosC,
      "nulidad": nulidad,
      "numHorasExtra": numHorasExtra,
      "otrasPrestaciones": otrasPrestaciones,
      "periodicidadSueldoBase": periodicidadSueldoBase,
      "periodicidadSueldoEstadistico": periodicidadSueldoEstadistico,
      "personaQueDespide": personaQueDespide,
      "porcentajePrimaVacacional": porcentajePrimaVacacional,
      "porProyecto": porProyecto,
      "primaAnt": primaAnt,
      "primaVacacional": primaVacacional,
      "razonPerAguinaldo": razonPerAguinaldo,
      "razonPerVac": razonPerVac,
      "reinstalacion": reinstalacion,
      "salariosCaidos": salariosCaidos,
      "salariosDevengados": salariosDevengados,
      "sueldoBase": sueldoBase,
      "sueldoEstadistico": sueldoEstadistico,
      "tipoJornada": tipoJornada,
      "trabajadorDeConfianza": trabajadorDeConfianza,
      "idExpediente": this.junta + "_" +this.expediente  + "_" + this.anio,
      "vacaciones": vacaciones


    }
    this.traerDatos();
    var pk= this.trabajadorAct["pk"];
    this.validaciones();
    this.upperCase(datosTrabajador);
    if(this.boolValidacionesTrabajador){
      
      this.uploadTrabajador(datosTrabajador,pk);
      
      this.mensaje="Trabajador actualizado correctamente.";
      this.myModal.toggle();
      
      this.actualizarAct=true;
      this.agregarAct=true;
     
    }else{
      //console.log("Error datos del trabajador ")
      this.mensaje="Hay campos vacíos por favor revise los datos ingresados.";
      this.myModal.toggle();
    }
    
    this.traerDatos();
    /*  this.apiDemanda.getAll(datosDemanda.idExpediente).then((res: Array<JSON>) => {
      var result = res;
      if (result.length > 0) {
        return this.apiDemanda.updateDem(datosDemanda);
      }
      else {
        return this.apiDemanda.createDem(datosDemanda);
      }
    }) */

  }
  terminarCaptura() {
    this.junta = (document.getElementById("junta") as HTMLInputElement).value;
    this.anio = (document.getElementById("anio") as HTMLInputElement).value;
    this.expediente = (document.getElementById("expediente") as HTMLInputElement).value;
    var idExpediente = this.junta + "_" +this.expediente + "_" + this.anio ;
    var observaciones = (document.getElementById("observaciones") as HTMLInputElement).value;
    var capturista= JSON.parse(localStorage.getItem("userIni"))["usuario"];
    
    this.apiCapturista.getAll(capturista).then((res: Array<JSON>) => {
      var result = res;
      
      if (result.length > 0) {
        return this.finalizarCaptura(idExpediente, observaciones,capturista, result[0]['fiscalizador'])
        
      }
      else {
        return [];
      }});
      
      
      
    /*this.getCapturista(capturista).then((res=>{
      res['fiscalizador']) -->
      return this.finalizarCaptura(idExpediente, observaciones,capturista, res['fiscalizador'])
    })
    ;*/
  }
  trabajadorDatos() {
    this.junta = (document.getElementById("junta") as HTMLInputElement).value;
    this.anio = (document.getElementById("anio") as HTMLInputElement).value;
    this.expediente = (document.getElementById("expediente") as HTMLInputElement).value;
    var alcaldia = (document.getElementById("alcaldia") as HTMLInputElement).value;
    var anioNacimiento = (document.getElementById("anioN") as HTMLInputElement).value;
    var nombre = (document.getElementById("wNombre") as HTMLInputElement).value;
    var apellidoPaterno = (document.getElementById("wApellidoPaterno") as HTMLInputElement).value;
    var apellidoMaterno = (document.getElementById("wApellidoMaterno") as HTMLInputElement).value;
    var codigoPostal = (document.getElementById("cp") as HTMLInputElement).value;
    var colonia = (document.getElementById("colonia") as HTMLInputElement).value;
    var genero = (document.getElementById("genero") as HTMLInputElement).value;

    var accionPrincipal = (document.getElementById("accion") as HTMLInputElement).value;
    var aguinaldo = (document.getElementById("aguinaldoS") as HTMLInputElement).value; //checar en la base hay error
    var categoria = (document.getElementById("categoria") as HTMLInputElement).value;
    var causa = (document.getElementById("causa") as HTMLInputElement).value;
    var cuotas = (document.getElementById("cuotas") as HTMLInputElement).checked;
    var fechaEntrada = (document.getElementById("fechaEntrada") as HTMLInputElement).value

    var fechaFinalAguinaldo = (document.getElementById("fechaFinalAguinaldo") as HTMLInputElement).value;
    var fechaFinalHorasExtra = (document.getElementById("fechaFinalHorasExtras") as HTMLInputElement).value;
    var fechaFinalVacaciones = (document.getElementById("fechaFinalVac") as HTMLInputElement).value;
    var fechaInicialAguinaldo = (document.getElementById("fechaInicialAguinaldo") as HTMLInputElement).value;
    var fechaInicialHorasExtra = (document.getElementById("fechaInicialHorasExtras") as HTMLInputElement).value;
    var fechaInicialVacaciones = (document.getElementById("fechaInicialVac") as HTMLInputElement).value;
    var fechaSalida = (document.getElementById("fechaSalida") as HTMLInputElement).value;
    var horasExtra = (document.getElementById("horasExtrasS") as HTMLInputElement).value;
    var horasLaboradas = (document.getElementById("horasLaboradas") as HTMLInputElement).value;
    var indemConst = (document.getElementById("indemnizacionConst") as HTMLInputElement).checked;
    var indemConst20 = (document.getElementById("indemnizacion20") as HTMLInputElement).checked;
    var montoIndemnizacion = (document.getElementById("montoIndemn") as HTMLInputElement).value;
    var montoIndemnizacion20 = (document.getElementById("montoIndemn20") as HTMLInputElement).value;
    var montoPrima = (document.getElementById("montoPrimaAntiguedad") as HTMLInputElement).value;
    var montoPrimaVacacional = (document.getElementById("montoPrimaVac") as HTMLInputElement).value;
    var montoSalariosC = (document.getElementById("montoSalariosCaidos") as HTMLInputElement).value;
    var nulidad = (document.getElementById("nulidad") as HTMLInputElement).checked;
    var numHorasExtra = (document.getElementById("numHorasExtras") as HTMLInputElement).value;
    var otrasPrestaciones = (document.getElementById("otrosMontosCuantificados") as HTMLInputElement).value;
    var periodicidadSueldoBase = (document.getElementById("periodoSueldo") as HTMLInputElement).value;
    var periodicidadSueldoEstadistico = (document.getElementById("periodoSueldoEst") as HTMLInputElement).value;
    var personaQueDespide = (document.getElementById("personaDespide") as HTMLInputElement).value;
    var porcentajePrimaVacacional = (document.getElementById("porcentajePrimaVac") as HTMLInputElement).value;
    var porProyecto = (document.getElementById("porProyecto") as HTMLInputElement).checked;
    var primaAnt = (document.getElementById("primaAntiguedad") as HTMLInputElement).checked;
    var primaVacacional = (document.getElementById("primaVacacional") as HTMLInputElement).checked;
    var razonPerAguinaldo = (document.getElementById("razonAguinaldo") as HTMLInputElement).value;
    var razonPerVac = (document.getElementById("razonPerVac") as HTMLInputElement).value;
    var reinstalacion = (document.getElementById("reinstalacion") as HTMLInputElement).checked;
    var salariosCaidos = (document.getElementById("salariosCaidos") as HTMLInputElement).checked;
    var salariosDevengados = (document.getElementById("salariosDeven") as HTMLInputElement).value;
    var sueldoBase = (document.getElementById("sueldoBase") as HTMLInputElement).value;
    var sueldoEstadistico = (document.getElementById("sueldoEst") as HTMLInputElement).value;
    var tipoJornada = (document.getElementById("tipoJornada") as HTMLInputElement).value;
    var trabajadorDeConfianza = (document.getElementById("trabConfianza") as HTMLInputElement).checked;
    var vacaciones = (document.getElementById("vacacionesRaz") as HTMLInputElement).value;
    if (indemConst == false) {
      montoIndemnizacion = "";
    }
    if (indemConst20 == false) {
      montoIndemnizacion20 = "";
    }
    if (primaAnt == false) {
      montoPrima = "";
    }
    if (salariosCaidos == false) {
      montoSalariosC = "";
    }

    var datosTrabajador = {
      'alcaldia': alcaldia,
      'anioNacimiento': anioNacimiento,
      'nombre': nombre,
      'apellidoPaterno': apellidoPaterno,
      'apellidoMaterno': apellidoMaterno,
      'codigoPostal': codigoPostal,
      'colonia': colonia,
      'genero': genero,
      "accionPrincipal": accionPrincipal,
      "aguinaldo": aguinaldo,
      "categoria": categoria,
      "causa": causa,
      "cuotas": cuotas,
      "fechaEntrada": fechaEntrada,
      "fechaFinalAguinaldo": fechaFinalAguinaldo,
      "fechaFinalHorasExtra": fechaFinalHorasExtra,
      "fechaFinalVacaciones": fechaFinalVacaciones,
      "fechaInicialAguinaldo": fechaInicialAguinaldo,
      "fechaInicialHorasExtra": fechaInicialHorasExtra,
      "fechaInicialVacaciones": fechaInicialVacaciones,
      "fechaSalida": fechaSalida,
      "horasExtra": horasExtra,
      "horasLaboradas": horasLaboradas,
      "indemConst": indemConst,
      "indemConst20": indemConst20,
      "montoIndemnizacion": montoIndemnizacion,
      "montoIndemnizacion20": montoIndemnizacion20,
      "montoPrima": montoPrima,
      "montoPrimaVacacional": montoPrimaVacacional,
      "montoSalariosC": montoSalariosC,
      "nulidad": nulidad,
      "numHorasExtra": numHorasExtra,
      "otrasPrestaciones": otrasPrestaciones,
      "periodicidadSueldoBase": periodicidadSueldoBase,
      "periodicidadSueldoEstadistico": periodicidadSueldoEstadistico,
      "personaQueDespide": personaQueDespide,
      "porcentajePrimaVacacional": porcentajePrimaVacacional,
      "porProyecto": porProyecto,
      "primaAnt": primaAnt,
      "primaVacacional": primaVacacional,
      "razonPerAguinaldo": razonPerAguinaldo,
      "razonPerVac": razonPerVac,
      "reinstalacion": reinstalacion,
      "salariosCaidos": salariosCaidos,
      "salariosDevengados": salariosDevengados,
      "sueldoBase": sueldoBase,
      "sueldoEstadistico": sueldoEstadistico,
      "tipoJornada": tipoJornada,
      "trabajadorDeConfianza": trabajadorDeConfianza,
      "idExpediente": this.junta + "_" + this.anio + "_" + this.expediente,
      "vacaciones": vacaciones


    }
    
  }
  goMenu(){
    this.router.navigate(['/menu/']);
  }
  mensajeDemandaTerminada(){
    this.mensaje="La demanda se termino de capturar exitosamente"
    this.myModal2.toggle();
  }

  seccionDemanda = false;
  seccionActores = false;
  seccionObservaciones = false;

  actoresDisplay() {
    if (this.seccionActores || this.demanda==undefined) {
      this.seccionActores = false;
      (document.getElementById("seccionActores") as HTMLBodyElement).style.display = "none";
      if (this.demanda==undefined){
        this.mensaje="Se deben capturar los datos de la demanda antes de poder capturar trabajadores."
        this.myModal.toggle();
      }
    } else {
      this.seccionActores = true;
      this.seccionDemanda = false;
      this.seccionObservaciones = false;


      (document.getElementById("seccionActores") as HTMLBodyElement).style.display = "block";
      (document.getElementById("seccionDemanda") as HTMLBodyElement).style.display = "none";
      (document.getElementById("seccionObservaciones") as HTMLBodyElement).style.display = "none";


    }



  }
  demandaDisplay() {
    if (this.seccionDemanda) {
      this.seccionDemanda = false;
      (document.getElementById("seccionDemanda") as HTMLBodyElement).style.display = "none";
    } else {
      this.seccionActores = false;
      this.seccionDemanda = true;
      this.seccionObservaciones = false;


      (document.getElementById("seccionActores") as HTMLBodyElement).style.display = "none";
      (document.getElementById("seccionDemanda") as HTMLBodyElement).style.display = "block";
      (document.getElementById("seccionObservaciones") as HTMLBodyElement).style.display = "none";

    }


  }
  observacionesDisplay() {
    if (this.seccionObservaciones) {
      this.seccionObservaciones = false;
      (document.getElementById("seccionObservaciones") as HTMLBodyElement).style.display = "none";
    } else {
      this.seccionActores = false;
      this.seccionDemanda = false;
      this.seccionObservaciones = true;


      (document.getElementById("seccionActores") as HTMLBodyElement).style.display = "none";
      (document.getElementById("seccionDemanda") as HTMLBodyElement).style.display = "none";
      (document.getElementById("seccionObservaciones") as HTMLBodyElement).style.display = "block";

    }


  }

  //Métodos para guardar información en el servidor
  uploadDemanda(datosDemanda) {
    /** Los datos deben de tener esta forma
    var datosDemanda={
      "junta":junta ,
      "anio":anio,
      "expediente":expediente,
      "abogado1":abogado1,
      "abogado2":abogado2,
      "abogado3":abogado3,
      "codemanda":codemandaSars,
      "despacho":despacho,
      "fechaDemanda":fechaDemanda,
      "giro":giro,
      "numDemandados":numDemandados,
      "numActores":numActores,
      "observaciones":observaciones,
      "prevencion": prevencion,
      "reclutamiento": reclutamiento,
      "tipoAbogado":tipoAbogado,
      "idExpediente":junta+"_"+anio+"_"+expediente,
      "observaciones":""} 
      */
    this.apiDemanda.getAll(datosDemanda.idExpediente).then((res: Array<JSON>) => {
      var result = res;
      if (result.length > 0) {
        return this.apiDemanda.updateDem(datosDemanda);
      }
      else {
        return this.apiDemanda.createDem(datosDemanda);
      }
    }).then(
      (result) => {
        console.log(result);
        
        
      },
      (error) => {
        console.log(error);
      }
    );
  }

  uploadTrabajador(datosTrabajador, trabajadorID?) {
    /**
     var datosTrabajador={

      FALTAN LOS DATOS DE PERSONA
    
    "accionPrincipal":accionPrincipal,
    "aguinaldo":aguinaldo, 
    "categoria":categoria, 
    "causa":causa, 
    "cuotas":cuotas, 
    "fechaEntrada":fechaEntrada, 
    "fechaFinalAguinaldo":fechaFinalAguinaldo, 
    "fechaFinalHorasExtra":fechaFinalHorasExtra,
    "fechaFinalVacaciones":fechaFinalVacaciones, 
    "fechaInicialAguinaldo":fechaInicialAguinaldo, 
    "fechaInicialHorasExtra":fechaInicialHorasExtra, 
    "fechaInicialVacaciones":fechaInicialVacaciones, 
    "fechaSalida":fechaSalida, 
    "horasExtra":horasExtra,
    "horasLaboradas":horasLaboradas, 
    "indemConst":indemConst, 
    "indemConst20":indemConst20, 
    "montoIndemnizacion":montoIndemnizacion,
    "montoIndemnizacion20":montoIndemnizacion20, 
    "montoPrima":montoPrima, 
    "montoPrimaVacacional":montoPrimaVacacional,
    "montoSalariosC":montoSalariosC, 
    "nulidad":nulidad, 
    "numHorasExtra":numHorasExtra, 
    "otrasPrestaciones":otrasPrestaciones, 
    "periodicidadSueldoBase":periodicidadSueldoBase, 
    "periodicidadSueldoEstadistico":periodicidadSueldoEstadistico, 
    "personaQueDespide":personaQueDespide,  
    "porcentajePrimaVacacional":porcentajePrimaVacacional,  
    "porProyecto":porProyecto, 
    "primaAnt":primaAnt,  
    "primaVacacional":primaVacacional, 
    "razonPerAguinaldo":razonPerAguinaldo,  
    "razonPerVac":razonPerVac,
    "reinstalacion":reinstalacion, 
    "salariosCaidos":salariosCaidos, 
    "salariosDevengados":salariosDevengados, 
    "sueldoBase":sueldoBase, 
    "sueldoEstadistico":sueldoEstadistico,  
    "tipoJornada":tipoJornada, 
    "trabajadorDeConfianza":trabajadorDeConfianza,
    "vacaciones":vacaciones, 
    "idExpediente":junta+"_"+anio+"_"+expediente
   }
     */
    var id = 0;
    if (trabajadorID) {
      id = trabajadorID;
    }
    this.apiTrabajador.getAll(datosTrabajador.idExpediente, id).then((res: Array<JSON>) => {
      var result = res;
      if (id!=0 && result.length > 0) {
        datosTrabajador['pk'] = id;
        return this.apiTrabajador.updateTra(datosTrabajador);
      }
      else {
        return this.apiTrabajador.createTra(datosTrabajador);
      }
    }).then(
      (result) => {
        console.log(result);
        this.traerDatos();
        
      },
      (error) => {
        console.log(error);
      }
    );
  }

  uploadControl(datosControl) {
    /**
     var datosControl={
      "estatus" = 'c'
      "fechaInicio" = fechaInicio ? 
      "fechaFin" = fechaCaptura 
      "capturista" = cuenta de capturista
      "fiscalizador" = fiscalizador asignado del capturista
      "idExpediente":junta+"_"+anio+"_"+expediente
     }
     */
    this.apiControl.getAll(datosControl.idExpediente).then((res: Array<JSON>) => {
      var result = res;
      if (result.length > 0) {
        return this.apiControl.updateCon(datosControl);
      }
      else {
        return this.apiControl.createCon(datosControl);
      }
    }).then(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //Métodos para obtener datos de las demandas

  getDemanda(idExpediente) {
    return this.apiDemanda.getAll(idExpediente);
  }

  getControl(idExpediente) {
    return this.apiControl.getAll(idExpediente);
  }

  getTrabajador(idExpediente, trabajadorID?: number) {
    var id = 0;
    if (trabajadorID) {
      id = trabajadorID;
    }
    return this.apiTrabajador.getAll(idExpediente, id);
  }

  getCapturista(correo) {
    return this.apiCapturista.getAll(correo);
  }
  //Método para verificar conexión
  checkGroup() {
    return this.apiLogin.getGroup();
  }

    /** 
  this.getCapturista(localStorage.getItem('userIni')['usuario']).then(res=>{
    res['fiscalizador']; -->Esto deberia ser un correo
    return this.finalizarCaptura(idExpediente, observaciones, res['fiscalizador'])
  })
  */

  //Método de botón de finalizar demanda
  //FALTA AGREGAR COMO PARÁMETRO EL CORREO DEL FISCALIZADOR
  finalizarCaptura(idExpediente, observaciones,capturista,fiscalizador) {
    var datosDemanda = {
      "idExpediente": idExpediente,
      "observaciones": observaciones
    }
    //Actualización de observaciones

    this.apiDemanda.getAll(datosDemanda.idExpediente).then((res: Array<JSON>) => {
      var result = res;
      if (result.length > 0) {
        return this.apiTrabajador.getAll(idExpediente);
      } 
      else {
        return [];
      }
    }).then((res: Array<JSON>) => {
      var result = res;
      if (result.length > 0) {
        var datosControl = {
          "estatus": "c",
          "fechaFin": this.today,
          "fiscalizador": fiscalizador,
          "idExpediente": idExpediente
        }
        return this.uploadControl(datosControl);
      }
      else {
        return false;
      }
    }).then(result => {
      if (result==false) {
        return false;
      }
      else {
        return this.uploadDemanda(datosDemanda);
      }
    }).then(result => {
      if (result==false) {
        this.mensaje="Para terminar la demanda es necesario llenar los datos y tener al menos un trabajador."
        this.myModal.toggle();
        return false;
      }
      else {
        this.mensajeDemandaTerminada();
        return result;
      }
    });
  }
}
