import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DatosGeneralesDemandaComponent } from './datos-generales-demanda/datos-generales-demanda.component';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { FiscalizacionComponent } from './fiscalizacion/fiscalizacion.component';
import { MenuFiscComponent } from './menu-fisc/menu-fisc.component';
import { CorregirComponent } from './corregir/corregir.component';
import { RevisarComponent } from './revisar/revisar.component';
import { AdministradorComponent } from './admin/administrador/administrador.component'
import { DomSanitizer } from "@angular/platform-browser";
import { Component, OnInit } from '@angular/core';
const routes: Routes = [

  {
    path:'captura',
    component:DatosGeneralesDemandaComponent,
  },
  {
    path:'login',
    component:LoginComponent,
  },
  {
    path:'menu',
    component:MenuComponent,
  },
  {
    path:'fiscalizacion/:exp',
    component:FiscalizacionComponent,
  },
  {
    path:'menuFisc/:datos',
    component:MenuFiscComponent,
  },
  {
    path:'corregir/:exp',
    component:CorregirComponent,
  },
  {
    path:'revisar/:exp',
    component:RevisarComponent,
  },
  {
    path:'menuAdmin',
    component:AdministradorComponent
  }
  
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
