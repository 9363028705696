<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="">
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 font-weight-normal">Ver expediente
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="numExp">Junta</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="juntaInf">
                            </div>
                        </div>
                        <div class="col">
                            <label for="numExp">Expediente</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="expInf">
                            </div>
                        </div>
                        <div class="col">
                            <label for="numExp">Año</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="anInf">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12 col-lg-12">
                            <button class="btn btn-greenL" type="button" (click)="traerInfo()">Ver información</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <div class="card m-0 p-0" *ngIf="traerInfoBandera">
                                <div class="card-body" id="infoExp">
                                    <div>
                                        <ul class="list-group">
                                            <li class="list-group-item active">Expediente: {{idExpedienteInfo}}</li>
                                            <li class="list-group-item">Capturista: {{capturistaInfo}} </li>
                                            <li class="list-group-item">Estatus: {{estatusInfo}}</li>
                                            <li class="list-group-item">Fecha del auto: {{fechaInfo}} </li>
                                            <li class="list-group-item">Fiscalizador: {{fiscalizadorInfo}} </li>
                                            <li class="list-group-item">{{fiscalizadoInfo}} </li>
                                          </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
    </div>
    <div class="row">
        <div class="col">
            <div class="card m-0 p-0">
                <div class="card-body">
                    <form [formGroup]="">
                        <div class="row">
                            <div class="col">
                                <h1 class="h4 font-weight-normal">Desasignar expediente
                                </h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="numExp">Junta</label>
                                <div class="form-group ">
                                    <input class="form-control" type="number" id="juntaDes">
                                </div>
                            </div>
                            <div class="col">
                                <label for="numExp">Expediente</label>
                                <div class="form-group ">
                                    <input class="form-control" type="number" id="expDes">
                                </div>
                            </div>
                            <div class="col">
                                <label for="numExp">Año</label>
                                <div class="form-group ">
                                    <input class="form-control" type="number" id="anDes">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <button class="btn btn-greenL" type="button"
                                    (click)="desasignarExp()">Desasignar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
    </div>
    
</div>
<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="">
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 font-weight-normal">Cambiar prioridad de un expediente
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="numExp">Junta</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="juntaP">
                            </div>
                        </div>
                        <div class="col">
                            <label for="numExp">Expediente</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="expP">
                            </div>
                        </div>
                        <div class="col">
                            <label for="numExp">Año</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="anP">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col" >
                                <label for="tipoColab">Prioridad </label>
                                <select class="form-control" id="prioridad">
                                    <option value="0">ALTA</option>
                                    <option value="1">MEDIA</option>
                                    <option value="2">BAJA</option>
                                    <option value="3">DESASIGNADO</option>
                                </select>
                            </div>

                        </div>
                        
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <button class="btn btn-greenL" type="button"
                                (click)="expPrioridad()">Cambiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
</div>
<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="" enctype="multipart/form-data" method="POST">
                    <input type="hidden" name="${_csrf.parameterName}" value="${_csrf.token}"/>
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 font-weight-normal">Subir ligas
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="numExp">Identificador de expediente</label>
                            <div class="form-group ">
                                <input class="form-control" type="file" id="ligas" multiple (change)="fileChanged($event)">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <button class="btn btn-greenL" type="button"
                                (click)="subirLigas()">Subir ligas</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="">
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 font-weight-normal">Eliminar expediente
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="numExp">Junta</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="juntaEl">
                            </div>
                        </div>
                        <div class="col">
                            <label for="numExp">Expediente</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="expEl">
                            </div>
                        </div>
                        <div class="col">
                            <label for="numExp">Año</label>
                            <div class="form-group ">
                                <input class="form-control" type="number" id="anEl">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <button class="btn btn-greenL" type="button"
                                (click)="eliminarExp()">Eliminar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{mensaje}}
        </div>
        <div class="modal-footer">
            <div class="row" >
                
                <div class="col">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    
                </div>
          
            </div>
        </div> 
      </div>
    </div>
  </div>
