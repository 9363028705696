<app-navbar></app-navbar>
<div class="row cuerpo">    
  <div class="col-12">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="row footer fixed-bottom" >
  <div class="col-12 d-flex justify-content-center align-items-center" >
    <p> © Derechos reservados al proyecto Improving Labour Courts dirigido por Joyce Sadka</p>
  </div>
</div>
