import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../services/login.service';
import { CapturistasService } from '../services/capturistas.service';
import { FiscalizadoresService } from '../services/fiscalizadores.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private login: LoginService , private apiCapturista: CapturistasService, private apiFiscalizador: FiscalizadoresService,private router: Router) { }
  

  ngOnInit(): void {
  }
  logout(){
    this.login.logout();
    
    this.router.navigate(['/login/']);
    
  } 
  goMenu(){
    var capturista= JSON.parse(localStorage.getItem("userIni"))["usuario"];
    
    this.apiCapturista.getAll(capturista).then((res: Array<JSON>) => {
      var result = res;
      
      if (result.length > 0) {
        this.router.navigate(['/menu/']);
        return[];
      }
      else {
        return this.apiFiscalizador.getAll(capturista).then((res: Array<JSON>) => {
          var result = res
          if (result.length > 0) {
            this.router.navigate(['/menuFisc/'+"Datos"]);
            return[];
          }
          else {
            this.router.navigate(['/menuAdmin/']);
            return [];
          };});

      }});
    
  }



}
