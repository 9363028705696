import { Component, OnInit } from '@angular/core';
import { ChildActivationStart, Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ControlesService } from '../services/controles.service';
import { CapturistasService } from '../services/capturistas.service';
import { FiscalizadoresService } from '../services/fiscalizadores.service';
import { LoginService } from '../services/login.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'; //Bibliotecas usadas para validacion
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-menu-fisc',
  templateUrl: './menu-fisc.component.html',
  styleUrls: ['./menu-fisc.component.css']
})
export class MenuFiscComponent implements OnInit {
  form: FormGroup;
  fechaInicio: any;
  fechaFin: any;
  porFiscalizaraux: Array<String>;
  expedienteActual: any;
  capturistasAsignadosAux: Array<any> = [];
  datos: string;
  correo: any;

  constructor(private route: ActivatedRoute, private router: Router, private apiControl: ControlesService, private formBuilder: FormBuilder, private apiCapturista: CapturistasService,
    private apiLogin: LoginService, private apiFiscalizador: FiscalizadoresService,) { }

  porFiscalizar: Array<String> = [];
  Fiscalizados: Array<String> = [];
  date: Date;
  today = new Date().toJSON().split('T')[0];
  buttonBool: boolean = false;
  usuario = JSON.parse(localStorage.getItem("userIni"))["usuario"];
  capturistasAsignados: any;




  boolporFiscalizar = true;
  boolFiscalizados = true;


  primeroACorregir: String;
  myModal;
  mensaje = "";

  ngOnInit(): void {
    this.myModal = new Modal(document.getElementById('exampleModal'));

    this.form = this.formBuilder.group({
      capturista: ['', Validators.required],
      semana: ['', Validators.required],
      porcentajeF: ['', Validators.required],
      fechaInicio: ['', Validators.required],
      fechaFin: ['', Validators.required],
    });

    this.checkLogin().then(result => {
      if (result == true) {
        this.checkLogin().then(result => {

          this.traerCapturistas().then(result => {
            if (this.route.snapshot.paramMap.get('datos') != "Datos") {
              var datos = this.datos.split("_");
              var capturistasAsignados = this.capturistasAsignadosAux.filter(ele => ele.correo == datos[0]);
              console.log(capturistasAsignados);
              var datos = this.datos.split("_");
              (document.getElementById("capturistas") as HTMLInputElement).value = capturistasAsignados["correo"];
              (document.getElementById("capturistas") as HTMLInputElement).value = datos[0];
              (document.getElementById("fechaInicio") as HTMLInputElement).value = datos[1];
              (document.getElementById("fechaFin") as HTMLInputElement).value = datos[2];
              (document.getElementById("porcentajeF") as HTMLInputElement).value = datos[3];
              console.log(datos[0]);
              this.traerDatos();

            }

          }
          );

        });
        this.datos = this.route.snapshot.paramMap.get('datos');






      }
    });
  }


  checkLogin() {
    return new Promise((resolve) => {
      this.apiLogin.getGroup().then(result => {
        if (result==null || result['grupo'] != 'Fiscalizador') {
          this.router.navigate(['/login/']);
          resolve(false);
        }
        else {
          resolve(true);
        }
      });
    });
  }

  muestraDiv(tipo) {
    switch (tipo) {
      case 1:
        this.boolporFiscalizar = false;
        this.boolFiscalizados = true;

        break;
      case 2:
        this.boolporFiscalizar = true;
        this.boolFiscalizados = false;

        break;

    }

  }
  traerDatos() {
    this.buttonBool = true;
    this.porFiscalizar = [];
    this.Fiscalizados = [];

    this.sortear();
    console.log(this.porFiscalizaraux);



  }
  borrar() {
    this.porFiscalizar = [];
    this.Fiscalizados = [];

  }
  fiscalizar() {
    if (this.porFiscalizar.length > 0) {
      var capturista = (document.getElementById("capturistas") as HTMLInputElement).value;
      var fechaInicio = (document.getElementById("fechaInicio") as HTMLInputElement).value;
      var fechaFin = (document.getElementById("fechaFin") as HTMLInputElement).value;
      var porcentaje = parseInt((document.getElementById("porcentajeF") as HTMLInputElement).value);

      this.router.navigate(['/fiscalizacion/' + this.expedienteActual + "_" + capturista + "_" + fechaInicio
        + "_" + fechaFin + "_" + porcentaje]);
    }
    else {
      this.mensaje = "No tienes expedientes a fiscalizar."
      this.myModal.toggle();
    }

  }
  corregir() {

  }
  traerCapturistas() {
    var username = this.usuario;
    console.log(username);
    return this.apiCapturista.getAll().then((result: Array<JSON>) => {


      console.log(result[0]["fiscalizador"]);
      this.capturistasAsignadosAux = result;
      console.log(this.capturistasAsignadosAux);
      this.capturistasAsignados = this.capturistasAsignadosAux.filter(ele => ele.fiscalizador == username);
      console.log(this.capturistasAsignados);

    }
    )

  }

  getCapturista(correo) {
    return this.apiCapturista.getAll(correo);
  }
  getFiscalizador(correo) {
    return this.apiFiscalizador.getAll(correo);
  }

  getControl(idExpediente, idCapturista) {
    return this.apiControl.getAll(idExpediente, idCapturista);
  }

  //Método para verificar conexión
  checkGroup() {
    return this.apiLogin.getGroup();
  }



  sortear() {

    var data = {
      "capturista": (document.getElementById("capturistas") as HTMLInputElement).value,
      "fechaInicio": (document.getElementById("fechaInicio") as HTMLInputElement).value,
      "fechaFin": (document.getElementById("fechaFin") as HTMLInputElement).value,
      "porcentaje": parseInt((document.getElementById("porcentajeF") as HTMLInputElement).value),
      "fiscalizador": this.usuario
    }

    console.log(data);
    this.apiControl.sortearExpedientes(data).then((result: Array<String>) => {
      console.log(result);

      this.porFiscalizaraux = result;
      console.log(this.porFiscalizaraux);
      this.expedienteActual = (this.porFiscalizaraux["idExpedientes"][0]);
      var i = 0;

      while (this.porFiscalizaraux["idExpedientes"][i] != "0") {
        this.porFiscalizar.push(this.porFiscalizaraux["idExpedientes"][i]);
        i++;

      }
      i++;
      while (this.porFiscalizaraux["idExpedientes"][i] != this.porFiscalizaraux.length) {
        this.Fiscalizados.push(this.porFiscalizaraux["idExpedientes"][i]);
        i++;
      }
      console.log(this.porFiscalizar);
      this.mensaje = "Expedientes que entraron a la fiscalización: " + this.porFiscalizaraux["expedientes"] + " Expedientes sorteados: "
        + this.porFiscalizaraux["expS"];
      this.myModal.toggle();



      //Imprime la lista de expedientes para fiscalizar y fiscalizados [[lista aFisc],0,[lista Fisc]]
    });
  }


}
