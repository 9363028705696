<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="">
                    <div class="row">
                        <div class="col">
                            <h1 class="h2 mb-3 font-weight-normal">Reportes</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button id="btnContinuar" class="btn btn-greenL" type="button"
                                (click)="reporteCSV()">Reporte CSV</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <button id="btnContinuar" class="btn btn-greenL" type="button"
                                (click)="reporteCSVServicio()">Reporte Servicio Social</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <label class="h4 mb-3 font-weight-normal">Mes de fiscalización: </label> 
                            <input id="mes" type="month" name="mes">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col"> 
                            <button id="btnContinuar" class="btn btn-greenL" type="button"
                                (click)="reporteCSVFisc()">Reporte Fiscalizadores</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Debe escoger un mes.
        </div>
        <div class="modal-footer">
            <div class="row" >
                
                <div class="col">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    
                </div>
          
            </div>
        </div> 
      </div>
    </div>
  </div>