<div class="row p-auto m-auto w-100 h-100">
    <div class="col-12 w-100 h-100">
      <div class="row m-3">
        <div class="col">
          <h1 class=" mb-3 text-center">Menú principal</h1>
        </div> 
      </div>
      <div class="row ">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="row">
                <h4 style="text-align: center;">Acciones en expedientes</h4>
                <h3 style="text-align: center;"><i class="bi bi-file-earmark-text"></i></h3>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-menu" (click)="goCaptura()">Capturar expediente</button>
                </div>
                <div class="col">
                  <button class="btn btn-menu" (click)="revisar()">Revisar expediente</button>
                </div>
                <div class="col">
                  <button class="btn btn-menu" (click)="corregir()">Corregir expediente</button>
                </div>
              </div>
            </div>
          </div> 
          <div class="row">
            <div class="col">
              <div class="row">
                <h4 style="text-align: center;">Listas para revisar</h4>
                <h3 style="text-align: center;"><i class="bi bi-eyeglasses"></i></h3>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-menu" (click)="muestraDiv(1)">Lista por Corregir</button>
                </div>
                <div class="col">
                  <button class="btn btn-menu" (click)="muestraDiv(2)">Lista Fiscalizados</button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button class="btn btn-menu" (click)="muestraDiv(3)">Lista Capturados</button>
                </div>
                <div class="col">
                  <button class="btn btn-menu" (click)="muestraDiv(4)">Lista Terminados</button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="col mx-auto">
          <div class="porCorregirDiv" [hidden]="boolPorCorregir">
            <h3 style="text-align: center;">Por Corregir</h3>
            <br>
            <table class="table table-striped" id="tCap">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Expediente</th>
                </tr>
              </thead>
              <tbody id="porCorregir">
                <tr *ngFor="let exp of porCorregir; let id = index">
                  <td>{{id+1}}</td>
                  <td>{{exp}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="FiscalizadosDiv" [hidden]="boolFiscalizados">
            <h3 style="text-align: center;">Fiscalizados</h3>
            <br>
            <table class="table table-striped" id="tFis">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Expediente</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let exp of porRevisar; let id = index">
                  <td>{{id+1}}</td>
                  <td>{{exp}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="CapturadosDiv" [hidden]="boolCapturados">
            <h3 style="text-align: center;">Capturados</h3>
            <br>
            <table class="table table-striped" id="tCorr">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Expediente</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let capturado of Capturados; let id = index">
                  <td>{{id+1}}</td>
                  <td>{{capturado}}</td>
                </tr>
              </tbody>
            </table>
            <br>
          </div>
          <div class="TerminadosDiv" [hidden]="boolTerminados">
            <h3 style="text-align: center;">Terminados</h3>
            <br>
            <table class="table table-striped" id="tTer">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Expediente</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let terminado of Terminados; let id = index">
                  <td>{{id+1}}</td>
                  <td>{{terminado}}</td>
                </tr>
              </tbody>
            </table>
            <br>
          </div>
        </div>
  
      </div>
      
    </div>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{mensaje}}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div>
  
