<div class="row p-auto m-auto w-100 h-100">
    <div class="col-12 w-100 h-100">
        <div class="row m-3">
            <div class="col">
                <h1 class=" mb-3 text-center">Menú principal</h1>
            </div>
        </div>
        <div class="row ">
            <div class="col">
                <div class="row">
                    <div class="col">


                        <form [formGroup]="form">
                            <div class="row">
                                <h4 style="text-align: center;">Datos para fiscalizar</h4>
                                <h3 style="text-align: center;"><i class="bi bi-file-earmark-text"></i></h3>
                            </div>
                            <div class="card-body">
                                <div class="row">

                                </div>
                                <div class="row">


                                    <div class="form-group ">
                                        <div class="col">
                                            <input class="btn btn-sm btn-green btn-block" type="reset"
                                                (click)="borrar()" value="Limpiar datos de la demanda">
                                            <br>
                                            <label for="capturista">Capturista</label>
                                            <select class="form-control" id="capturistas" formControlName="capturista">
                                                <option *ngFor="let repre of capturistasAsignados;  let id = index"
                                                    value="{{repre.correo}}">
                                                    {{repre.nombre}}</option>
                                            </select>
                                            <div class="error-msg"
                                                *ngIf="(this.form.get('capturista').invalid && this.form.get('capturista').touched) || (this.form.get('capturista').invalid && buttonBool)">
                                                ERROR: Informacion requerida
                                            </div>

                                        </div>


                                        <div class="col">
                                            <label for="porcentajeF">Porcentaje</label>
                                            <select class="form-control" id="porcentajeF" formControlName="porcentajeF">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="40">40</option>
                                                <option value="50">50</option>
                                                <option value="60">60</option>
                                                <option value="70">70</option>
                                                <option value="80">80</option>
                                                <option value="90">90</option>
                                                <option value="100">100</option>
                                            </select>
                                            <div class="error-msg"
                                                *ngIf="(this.form.get('porcentajeF').invalid && this.form.get('porcentajeF').touched ) || (this.form.get('porcentajeF').invalid && buttonBool)">
                                                ERROR: Informacion requerida
                                            </div>


                                        </div>
                                        <div class="col">
                                            <label for="semana">Fecha inicial</label>
                                            <input class="form-control" type="date" id="fechaInicio" onKeyDown="return"
                                                #t [max]="today" md-datepicker-filter="onlyMonday(t.value)"
                                                formControlName="fechaInicio">
                                            <div class="error-msg"
                                                *ngIf="(this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched) || (this.form.get('fechaInicio').invalid && buttonBool) ">
                                                ERROR: Información requerida
                                            </div>

                                        </div>
                                        <div class="col">
                                            <label for="semana">fecha final</label>
                                            <input class="form-control" type="date" id="fechaFin" onKeyDown="return" #t
                                                [max]="today" md-datepicker-filter="onlyMonday(t.value)"
                                                formControlName="fechaFin">
                                            <div class="error-msg"
                                                *ngIf="(this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched) || (this.form.get('fechaFin').invalid && buttonBool) ">
                                                ERROR: Información requerida
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div class="row">


                                </div>

                            </div>



                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-menu" (click)="traerDatos()">Traer datos</button>
                                </div>
                                <div class="col">
                                    <button class="btn btn-menu" (click)="fiscalizar()"
                                        [disabled]="this.form.invalid && !buttonBool">Fiscalizar</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>




            </div>
            <div class="col">
                <div class="row">
                    <h4 style="text-align: center;">Listas para revisar</h4>
                    <h3 style="text-align: center;"><i class="bi bi-eyeglasses"></i></h3>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="porFiscalizarDiv">
                            <h3 style="text-align: center;">Por fiscalizar</h3>
                            <br>
                            <table class="table table-striped" id="tCap">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Expediente</th>
                                    </tr>
                                </thead>
                                <tbody id="porFiscalizar">
                                    <tr *ngFor="let exp of porFiscalizar; let id = index">
                                        <td>{{id+1}}</td>
                                        <td>{{exp}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="col">
                        <div class="FiscalizadosDiv">
                            <h3 style="text-align: center;">Fiscalizados</h3>
                            <br>
                            <table class="table table-striped" id="tCorr">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Expediente</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let capturado of Fiscalizados; let id = index">
                                        <td>{{id+1}}</td>
                                        <td>{{capturado}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                        </div>

                    </div>
                </div>




            </div>

        </div>

    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                {{mensaje}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

            </div>
        </div>
    </div>
</div>