import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { ChildActivationStart, Router } from  "@angular/router";
import { LigasService } from 'src/app/services/ligas.service';
import { ControlesService } from 'src/app/services/controles.service';

@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.css']
})
export class AdministradorComponent implements OnInit {

  constructor(private apiLogin:LoginService, private router:Router, private apiLigas:LigasService, private apiControles:ControlesService) { }

  panelColaboradores = true;
  panelExpedientes = false;
  panelReportes = false;
  panelFiscalizador = false;
  exp=[];
  des=[];
  controles=[];
  pendientes;
  desasignados;
  ngOnInit(): void {
    this.checkLogin();
    this.getExpedientes();
  }


  checkLogin(){
    this.apiLogin.getGroup().then(result=>{
      if (result==null || result['grupo']!='Admin'){
        this.router.navigate(['/login/']);
      }
    });
  }

  gestionColaboradores(){
    this.panelColaboradores = true;
    this.panelExpedientes = false;
    this.panelReportes = false;
    this.panelFiscalizador = false;
  }

  asignarFiscalizacion(){
    this.panelColaboradores = false;
    this.panelExpedientes = false;
    this.panelReportes = false;
    this.panelFiscalizador = true;
  }

  reportes(){
    this.panelColaboradores = false;
    this.panelExpedientes = false;
    this.panelReportes = true;
    this.panelFiscalizador = false;
  }

  borrarExpediente(){
    this.panelColaboradores = false;
    this.panelExpedientes = true;
    this.panelReportes = false;
    this.panelFiscalizador = false;
  }

  getExpedientes(){
    this.apiLigas.getAll().then((res:Array<JSON>) => {
      for (let i=0;i<res.length;i++){
        if (res[i]["prioridad"]<3){
          this.exp.push(res[i]["idExpediente"]);
        }
        else
          this.des.push(res[i]["idExpediente"]);
      }
      this.apiControles.getAll().then((data:Array<JSON>) => {
        for (let i=0;i<data.length;i++){
          this.controles.push(data[i]["idExpediente"]);
        }
        this.pendientes = this.exp.filter(x=>!this.controles.includes(x)).length;
        this.desasignados = this.des.length;
      });
    });
  }

}
